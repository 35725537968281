<app-navbar></app-navbar>
<section class="chain_side_navbar" id="chain-data-det">
<section class="mt-3 option_det_chain">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-lg-12">
        <div class="card shadow cardToggle">
          <div class="card-header relative_card_header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 col-lg-3 col-xl-3">
                  <div id="sample">
                  <h5 class="vertical">LAST UPDATED: <span id="optionChaindateDetails"></span></h5>
                  </div>
                </div>
                <div class="col-md-10 col-lg-7 col-xl-6 m-auto pl-md-2 pl-lg-0 pl-xl-0">
                  <form class="form-inline" action="/action_page.php">
                    <label class="mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Symbol:</label>
                    <select class="custom-select my-select" id="optionChainNifty">
                    </select>
                    <label class="mr-md-2 ml-md-2 ml-lg-2 ml-xl-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Expiry:</label>
                    <select class="custom-select my-select" id="expiryChainNifty">
                    </select>
                    <button type="submit" onclick="optionDatatable()" class="btn btnRelative  ml-md-2 ml-lg-2 ml-xl-2 btninputfield ">Show Chart</button>
                  </form>
                </div>
                <div class="col-md-2 col-lg-2 col-xl-3">
                  <div class="d-flex align-items-center float-md-right float-lg-right float-xl-right">
                    <div class="d-flex align-items-center">
                      <div id="sample">
                      <h6 class="vertical2 pl-lg-2 pl-md-2 pr-1">IV</h6>
                    </div>
                      <p class="mr-md-2 mr-lg-2 mr-xl-2 mr-3 chain_toggle">
                        <label class="toggleSwitch nolabel" onclick="">
                          <input onclick="myToggle()" type="checkbox" checked/>
                          <span>
                            <span>OFF</span>
                            <span>ON</span>
                          </span>
                          <a></a>
                        </label>
                      </p>
                    </div>

                    <div class="d-flex align-items-center">
                      <div id="sample">
                      <h6 class="pl-md-3 pl-lg-3 pr-1 vertical2">OI<span>&nbsp;CHANGE</span></h6>
                      </div>
                      <p class="mr-2 chain_toggle">
                        <label class="toggleSwitch nolabel" onclick="">
                          <input onclick="myOIchange()" type="checkbox" checked/>
                          <span>
                            <span>OFF</span>
                            <span>ON</span>
                          </span>
                          <a></a>
                        </label>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
           </div>

          <div class="cardbody">
            <!-- <div class="container"> -->
              <!-- <div class="row"> -->
                <!-- <div class="col-sm-3 col-lg-3 align-items-center d-flex justify-content-center">
                  <h6 class="oiDetails font-weight-bold">LAST UPDATED: <span id="optionChaindateDetails"></span></h6> 
                </div> -->
                <!-- <div class="col-sm-3 col-lg-3 align-items-center d-flex justify-content-center">
                  <div class="d-flex align-items-center">
                    <h6>Refresh In Sec:</h6>
                    <div id="countdown">
                      <div id="countdownpcr"></div>
                      <svg>
                        <circle r="18" cx="20" cy="20"></circle>
                      </svg>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="col-sm-3 col-lg-3 align-items-center d-flex justify-content-center">
                </div> -->
                <!-- <div class="col-sm-3 col-lg-3 align-items-center d-flex justify-content-center">
                  <div class="d-flex align-items-center">
                    <h6>OI CHANGE</h6>
                    <p class="mr-2 ml-2">
                      <label class="toggleSwitch nolabel" onclick="">
                        <input onclick="myOIchange()" type="checkbox" checked/>
                        <span>
                          <span>OFF</span>
                          <span>ON</span>
                        </span>
                        <a></a>
                      </label>
                    </p>
                  </div>
                </div> -->
              <!-- </div> -->
            <!-- </div> -->
            <div class="table-responsive p-3">
              <div id='div1'> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <button class="btn" (click)="goToVotes()">Check votes</button> -->
</section>
<div class="header-css-class" id="grade"></div>
<!-- <div id="chain_session_expired" class="chain_expired">
  <div class="session-chain">
  <span class="closeDasboard" id="closeTag">&times;</span>
  <p id="chainSessionModel"></p>
   </div>
   </div> -->
   <div class="card dasbaord_expired" id="dashboard_session_expired">
    <h5 class="card-header headerSession">Session Expired</h5>
    <div class="card-body session-square">
      <div class="d-flex clockDash">
        <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
        <i class="fa fa-sign-in" aria-hidden="true"></i>
        <p id="dasboardSessionModel"></p>
     </div>
     <div class="text-center">
         <button type="submit" class="btn dashboardCancel mr-3">ok</button>
         </div>
    </div>
  </div>
  <div class="spinner"><!-- Place at bottom of page --></div>
<!-- <a routerLink="/scalper" routerLinkActive="active">jgshgdxh</a> -->
<app-footer></app-footer>