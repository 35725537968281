import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { DataTablesModule } from "angular-datatables";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { NavbarComponent } from './navbar/navbar.component';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RelativeStrengthComponent } from './relative-strength/relative-strength.component';
import { IndexFiiDataComponent } from './index-fii-data/index-fii-data.component';
import { OptionAnalysisComponent } from './option-analysis/option-analysis.component';
import { OptionChainComponent } from './option-chain/option-chain.component';
import { IndexmeterComponent } from './indexmeter/indexmeter.component';
import { StraddlesComponent } from './straddles/straddles.component';
import { LoginformComponent } from './loginform/loginform.component';
import { ScaplerstrikeComponent } from './scaplerstrike/scaplerstrike.component';
import { PcrComponent } from './pcr/pcr.component';
import { OptionoiComponent } from './optionoi/optionoi.component';
import { VolumespeaksComponent } from './volumespeaks/volumespeaks.component';
import { FooterComponent } from './footer/footer.component';
import { FutureCOIComponent } from './future-coi/future-coi.component';
import { EodchartsComponent } from './eodcharts/eodcharts.component';
import { OptionsComponent } from './options/options.component';
import { VolumespeakseodComponent } from './volumespeakseod/volumespeakseod.component';
import { StrategybuilderComponent } from './strategybuilder/strategybuilder.component';
import { ContactusComponent } from './contactus/contactus.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DashboardComponent,
    RelativeStrengthComponent,
    IndexFiiDataComponent,
    OptionAnalysisComponent,
    OptionChainComponent,
    IndexmeterComponent,
    StraddlesComponent,
    LoginformComponent,
    ScaplerstrikeComponent,
    PcrComponent,
    OptionoiComponent,
    VolumespeaksComponent,
    FooterComponent,
    FutureCOIComponent,
    EodchartsComponent,
    OptionsComponent,
    VolumespeakseodComponent,
    StrategybuilderComponent,
    ContactusComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DataTablesModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
