import { Component , OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from "rxjs/operators";
// import { DataService } from './data.service';
// declare var  anychart1 : any;
// declare var  barChart : any;
// declare var  graph : any;
// declare var  queueChart1 : any;
// declare var  tableDet : any;
// declare var  queueChart : any;
// declare var  columnChart : any;
// declare var  rowChart : any;
// declare var  scalperChart : any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly titleService: Title
  ) { }

  btnClick= function () {
    this.router.navigateByUrl('/scalper');
};

  ngOnInit(): void {  
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.titleService.setTitle(this.getNestedRouteTitles().join(' | '));
    });
    
    // columnChart();
   
    // barChart(); 
    // rowChart();
    // anychart1();
    // queueChart();
    // queueChart1();
    // scalperChart();
    
}
getNestedRouteTitles(): string[] {
  let currentRoute = this.router.routerState.root.firstChild;
  const titles: string[] = [];

  while (currentRoute) {
    if (currentRoute.snapshot.routeConfig.data?.title) {
      titles.push(currentRoute.snapshot.routeConfig.data.title);
    }

    currentRoute = currentRoute.firstChild;
  }

  return titles;
}
getLastRouteTitle(): string {
  let currentRoute = this.router.routerState.root.firstChild;

  while (currentRoute.firstChild) {
    currentRoute = currentRoute.firstChild;
  }

  return currentRoute.snapshot.data?.title;
}



}
