import { Component, OnInit } from '@angular/core';
declare var straddleChart,straddleTimer,straddleDataListApi,load,sessionLoad,hidetimer: any;
declare var  start : any;

@Component({
  selector: 'app-straddles',
  templateUrl: './straddles.component.html',
  styleUrls: ['./straddles.component.css']
})
export class StraddlesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $("#selectSymbol").change(function () { straddleDataListApi( $('#selectSymbol option:selected').text())});
    // straddleTimer();
    start();
    straddleDataListApi('')
    sessionLoad();
    
    straddleChart();
    
    load();
    hidetimer();
    
  }
  ngAfterContentInit(): void 
  {
    straddleTimer();
    
  }
  ngOnDestroy() {
    stop()
  }
  

}
