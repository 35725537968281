<app-navbar></app-navbar>
<section class="volume_side_navbar" id="volume-data-det">
<section class="firstChart mt-3">
<div class="container-fluid">
  <div class="card shadow">
  <div class="card-header relative_card_header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-2 col-lg-2 col-xl-2">
          <div id="sample">
          <!-- <h5 class="vertical" id="volumeNumber"></h5> -->
          <h5 class="vertical">Volume Speaks</h5>
          </div>
        </div>
        <div class="col-md-10 col-lg-9 col-xl-9 m-auto">
          <form class="form-inline volume_form" action="/action_page.php">
            <label class="filterClass mr-md-2 mr-lg-2 mr-xl-2">Filter Type :</label>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios" value="option1" checked>
                              <label class="form-check-label volumeSpeaksLabel" for="gridRadios1">
                                History
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios" value="option2">
                              <label class="form-check-label volumeSpeaksLabel" for="gridRadios2">
                                Week
                              </label>
                            </div> 
                            <label class="ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2" for="sel1">Select Date:</label>
                            <select class="custom-select my-select" id="volumeDatesList">
                          
                            </select>
            <button type="submit" onclick="volumeInputLoad()" class="btn btnRelative ml-md-2 ml-lg-2 ml-xl-2 btninputfield ">Show Chart</button>
          </form>
        </div>
        <!-- <div class="col-md-2 col-lg-1 col-xl-1">
          <div class="d-flex justify-content-center">
            <div class="spinner-border text-success" id="spinner" role="status"> 
                   <span class="sr-only">Loading...</span> 
           </div> 
      </div>
        </div> -->
      </div>
    </div>

   </div>
    <div class="card-body">
  <div class="d-flex row append-here"></div>

  </div>
</div>
</div>
</section>  

<!-- <section>
  <div id="newBeeId"></div>
</section> -->
</section>


   <div class="card vol_expired" id="vol_session_expired">
    <h5 class="card-header headerSession">Session Expired</h5>
    <div class="card-body session-square">
      <div class="d-flex clockDash">
        <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
        <i class="fa fa-sign-in" aria-hidden="true"></i>
        <p id="volSessionModel"></p>
     </div>
     <div class="text-center">
         <button type="submit" class="btn volCancel mr-3">ok</button>
         </div>
    </div>
  </div>

  <div class="spinner"><!-- Place at bottom of page --></div>
<app-footer></app-footer>