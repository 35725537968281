import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import '/src/assets/js/optionanalysis.js';
declare var  colorChange,optionChainOnLoad,sessionLoad,strategyOnLoad: any;
declare var  start,timerChain,hidetimer : any; 
@Component({
  selector: 'app-option-chain',
  templateUrl: './option-chain.component.html',
  styleUrls: ['./option-chain.component.css']
})
export class OptionChainComponent implements OnInit {
  msg:string;
  constructor(private router: Router) {}
  clickme(){
    this.msg='anchor tag is Clicked';
    alert(this.msg);
  }



  goToVotes($myParam: string = ''): void {
    const navigationDetails: string[] = ['/scalper'];
    if($myParam.length) {
      navigationDetails.push($myParam);
    }
    this.router.navigate(navigationDetails);
  }
  ngOnInit(): void 
  {
    sessionLoad();
    start();
    colorChange();
    optionChainOnLoad();
    hidetimer();
  }
  ngAfterContentInit(): void 
  {
    timerChain();
    
  }
  ngOnDestroy() {
    stop()
  }

}
