import { Component, OnInit } from '@angular/core';
declare var  columnChart2,scalperTimer: any;
declare var  anychartfirst, dashboardApiLoad : any;
declare var  barChart : any;
declare var  graph : any;
declare var  queueChart1,queueChart2,queueChart3,queueChart4,queueChart5,queueChart6,queueChart7,queueChart8,queueChart9,queueChart10,dataHeader: any;
declare var  tableDet : any;
declare var  queueChart : any;
declare var  start,dashboardTimer,sidenavbar : any;  
// declare var  secondDashChart : any;
declare var  rowChart,hidetimer : any;
declare var  upload: any;
// declare var  apiCall : any;
declare global { interface Window { JsonAPI: any; } }
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // this.StartData();
    // this.apiCall();
    // scalperTimer();
    dashboardApiLoad(),anychartfirst(),columnChart2(),rowChart(),queueChart2(),queueChart(),queueChart1(),queueChart3(),queueChart4(),queueChart5(),queueChart6(),queueChart7(),queueChart9(),queueChart10(),queueChart8(),dataHeader();
    // dashboardTimer();
    // sidenavbar();
    start();
    hidetimer();
    
}

//  StartData() {
//       const countTimer = 0;
//       this.apiCall();
//       setInterval(this.apiCall, 3000);
//   }
ngAfterContentInit(): void 
{
  dashboardTimer();
  
}
ngOnDestroy() {
  stop()
}




}
