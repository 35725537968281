<app-navbar></app-navbar>
<section class="future_side_navbar" id="future-data-det">
<!-- <section class="volumespeaksHeader">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12">
                <div class="card cardInputDet mt-4 shadow">
                    <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">INPUT</h6>
                    </div>s
                    <div class="card-body m-auto">




                          
                          <form class="form-inline" action="/action_page.php">
                           
                            <label class="text-muted filterClass mr-md-2 mr-lg-2 mr-xl-2">Filter Type :</label>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios" value="option1" data-value="true">
                              <label class="form-check-label" for="gridRadios1">
                                History
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2" data-value="false">
                              <label class="form-check-label" for="gridRadios2">
                                Week
                              </label>
                            </div> 
                            <label class="text-muted ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2" for="sel1">Select Date:</label>
                            <select class="custom-select my-select" id="futueCoiDatesList">
                          
                            </select>
                         
                      
                            <button type="submit" onclick="futureInputLoad()" class="btn btnVolume btn-primary font-weight-bold ml-md-2 ml-lg-2 ml-xl-2">Show Chart</button>
                          </form>
                   </div>
                    </div> 
            </div>
        </div>
    </div>
</section> -->
<section class="futureCoiSectionDet mt-3">
<div class="container-fluid">
  <div class="card shadow">


    <div class="card-header relative_card_header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-2 col-lg-2 col-xl-2">
            <div id="sample">
            <!-- <h5 class="vertical" id="futureNumber"></h5> -->
            <h5 class="vertical">Future Coi</h5>
            </div>
          </div>
          <div class="col-md-10 col-lg-9 col-xl-9 m-auto">
            <form class="form-inline volume_form" action="/action_page.php">
              <label class="filterClass mr-md-2 mr-lg-2 mr-xl-2">Filter Type :</label>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios" value="option1" data-value="true">
                <label class="form-check-label volumeSpeaksLabel" for="gridRadios1">
                  History
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2" data-value="false">
                <label class="form-check-label volumeSpeaksLabel" for="gridRadios2">
                  Week
                </label>
              </div> 
              <label class="ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2" for="sel1">Select Date:</label>
              <select class="custom-select my-select" id="futueCoiDatesList">
            
              </select>
              <button type="submit" onclick="futureInputLoad()" class="btn btnRelative ml-md-2 ml-lg-2 ml-xl-2 btninputfield ">Show Chart</button>
            </form>
          </div>
          <!-- <div class="col-md-2 col-lg-1 col-xl-1">
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-success" id="spinner" role="status"> 
                     <span class="sr-only">Loading...</span> 
             </div> 
        </div>
          </div> -->
        </div>
      </div>
  
     </div>
    <div class="card-body">
  <div class="row futureJsonDiv">
    <div class="d-flex row futureJsonDiv"></div>
    
  </div>
  </div>
</div>
</div>
</section>
</section>
<div class="card future_expired" id="future_session_expired">
    <h5 class="card-header headerSession">Session Expired</h5>
    <div class="card-body session-square">
      <div class="d-flex clockDash">
        <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
        <i class="fa fa-sign-in" aria-hidden="true"></i>
        <p id="futureSessionModel"></p>
      </div>
      <div class="text-center">
         <button type="submit" class="btn futureCancel mr-3">ok</button>
      </div>
    </div>
  </div>
<!-- 
<section class="firstChart mt-3">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="container-fluid">
          <div class="row">
            <div class="col-md-4 cardmulti">
         <div class="card shadow cardAreaPieChart">
          <div class="card-header py-3">
          </div>
         <div class="card-body">
         </div>
           </div>
           </div>
          </div>
          </div>
         </div>
       </div>
      </div>
</section> -->

<div class="spinner"><!-- Place at bottom of page --></div>
<app-footer></app-footer>