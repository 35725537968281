import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ScaplerstrikeComponent } from './scaplerstrike/scaplerstrike.component';
import { RelativeStrengthComponent } from './relative-strength/relative-strength.component';
import { IndexFiiDataComponent } from './index-fii-data/index-fii-data.component';
import { OptionAnalysisComponent } from './option-analysis/option-analysis.component';
import { OptionChainComponent } from './option-chain/option-chain.component';
import { IndexmeterComponent } from './indexmeter/indexmeter.component';
import { StraddlesComponent } from './straddles/straddles.component';
import { PcrComponent } from './pcr/pcr.component';
import { LoginformComponent } from './loginform/loginform.component';
import { OptionoiComponent } from './optionoi/optionoi.component';
import { VolumespeaksComponent } from './volumespeaks/volumespeaks.component';
import { FutureCOIComponent } from './future-coi/future-coi.component';
import { EodchartsComponent } from './eodcharts/eodcharts.component';
import { OptionsComponent } from './options/options.component';
import { VolumespeakseodComponent } from './volumespeakseod/volumespeakseod.component';
import { StrategybuilderComponent } from './strategybuilder/strategybuilder.component';
import { ContactusComponent } from './contactus/contactus.component';

const routes: Routes = [
  { path: '', component: LoginformComponent,
  data: { title: 'Deltacapitis | Login' }, 

},
  { path: 'login', component: LoginformComponent,
  data: { title: 'Deltacapitis | Login' }, 

},
  { path: 'dashboard', component: DashboardComponent,
  data: { title: 'Dashboard' }, 
},
  { path: 'scalper', component: ScaplerstrikeComponent,
  data: { title: 'Scalper' }, 
},
  { path: 'relative_strength', component: RelativeStrengthComponent,
  data: { title: 'Relative strength' }, 
 },
  { path: 'index_fii_data', component: IndexFiiDataComponent,
  data: { title: 'Index Fii Data' },
 },
  { path: 'IV_Overview', component: OptionAnalysisComponent,
  data: { title: 'IV Overview' },
 },
  { path: 'optionChain', component: OptionChainComponent,
  data: { title: 'Option Chain' },
 },
  { path: 'indexmeter', component: IndexmeterComponent,
  data: { title: 'Indexmeter' },
 },
  { path: 'Straddles/Strangle', component: StraddlesComponent,
  data: { title: 'Straddles/Strangle' },
 },
  { path: 'PCR', component: PcrComponent,
  data: { title: 'PCR' },
 },
  { path: 'Option_OI_Analysis', component: OptionoiComponent,
  data: { title: 'Option OI Analysis' },
 },
 { path: 'Volume_speaks', component: VolumespeaksComponent,
 data: { title: 'Volume Speaks' },
},
{ path: 'Future_Coi', component: FutureCOIComponent,
data: { title: 'Future COI' },
},
{ path: 'Eod_Charts', component: EodchartsComponent,
data: { title: 'Eod_Charts' },
},
{ path: 'options', component: OptionsComponent,
data: { title: 'options' },
},
{ path: 'futures_charts', component: VolumespeakseodComponent,
data: { title: 'futures_charts' },
},
{ path: 'strategy_builder', component: StrategybuilderComponent,
data: { title: 'strategy_builder' },
},
{ path: 'Contact_Us', component: ContactusComponent,
data: { title: 'Contact Us' },
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
