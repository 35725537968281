import { Component, OnInit } from '@angular/core';
declare var EodChartsOnload,eodChartOnLoading,sessionLoad,hideReload:any;
@Component({
  selector: 'app-eodcharts',
  templateUrl: './eodcharts.component.html',
  styleUrls: ['./eodcharts.component.css']
})
export class EodchartsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    EodChartsOnload();
    // getActualYear();
    // eodChartOnLoading();
    sessionLoad();
    hideReload();
  }

}
