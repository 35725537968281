<app-navbar></app-navbar>




<section class="dashboardChart" id="testingDet">
<section>
  <div class="container-fluid mt-3">
    <div class="row">

      <!-- Area Chart -->
      <div class="col-xl-6 col-lg-6 pr-md-3 pr-lg-0 pr-xl-0 mb-md-3 mb-lg-0 mb-xl-0 mb-3">
        <div class="card shadow cardAreaPieChart">
          <!-- Card Header - Dropdown -->
          <div class="card-header d-flex flex-row align-items-center justify-content-between dashboard_card_header">
            <div class="nav">
              <div class='panel-title dashboard_nifty_details'><b class="market_nifty">NIFTY:</b>&nbsp;<span style="font-size:14px;color:#1671CF;" id="lbPrice" ></span><span style="font-size:12px;margin-left:5px;" id="lbPriceChg" > </span><label style="font-size:11px;color:black;margin-left:10px"><b>High</b> : </label><label style="font-size:11px;color:green;margin-left:5px" id="lbPriceHigh" > </label><span style="font-size:11px;color:black;margin-left:10px"><b>Low :</b> </span><label style="font-size:11px;color:red;margin-left:5px" id="lbPriceLow" > </label></div>
            </div>
          </div>
          <!-- Card Body -->
          <div class="card-body">
            <div class="chart-area">
              <!-- <canvas id="pie"></canvas> -->
              <div id="firstNifty"></div>
            </div>
          </div>
        </div>
</div>
            <!-- Area Chart -->
  <div class="col-xl-6 col-lg-6 colCandleTwoChart">
        <div class="card shadow cardAreaPieChart">
          <!-- Card Header - Dropdown -->
          <div class="card-header d-flex flex-row align-items-center justify-content-between dashboard_card_header">
            <div class="nav">
              <div class='panel-title dashboard_nifty_details'><b class="market_nifty">BANKNIFTY:</b>&nbsp;<span style="font-size:14px;color:#1671CF;" id="lbPrice1"></span><span style="font-size:12px;margin-left:5px;" id="lbPriceChg1" > </span><label style="font-size:11px;color:black;margin-left:10px"><b>High</b> : </label><label style="font-size:11px;color:green;margin-left:5px" id="lbPriceHigh1" > </label><span style="font-size:11px;color:black;margin-left:10px"><b>Low :</b> </span><label style="font-size:11px;color:red;margin-left:5px" id="lbPriceLow1" > </label></div>
            </div>
          </div>
          <!-- Card Body -->
          <div class="card-body">
          <div class="chart-area">
            <!-- <canvas id="barLine"></canvas> -->
            <div id="dataPrice"></div>
          </div>
          </div>
        </div>
  </div>

</div>
</div>

</section>



     <!-- Content Row -->
  <section class="aboveVwapDet mt-3">
     <div class="container-fluid mt-3">
      <div class="row">
  
          <!-- Content Column -->
          <div class="col-xl-3 col-lg-3 pr-md-3 pr-lg-0 pr-xl-0 mb-md-3 mb-lg-0 mb-xl-0 mb-3">
  
            <!-- Project Card Exampl-mde -->
            <div class="card shadow cardProgressPieChart">
              <div class="card-header dashboard_card_header">
                <h6 class="m-0 font-weight-bold text-md-left text-lg-left text-center">ABOVE VWAP</h6>
              </div>
              <div class="card-body">
                <div class="chart-area">
                  <div id="fireData"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 pl-md-3 pr-md-3 pr-lg-0 pr-xl-0 mb-md-3 mb-lg-0 mb-xl-0 mb-3">
  
            <!-- Project Card Exampl-mde -->
            <div class="card shadow cardProgressPieChart">
              <div class="card-header dashboard_card_header">
                <h6 class="m-0 font-weight-bold text-md-left text-lg-left text-center">BELOW VWAP</h6>
              </div>
              <div class="card-body">
                <div id="gasChart"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3  pr-md-3 pr-lg-0 pr-xl-0 mb-md-3 mb-lg-0 mb-xl-0 mb-md-0 mb-3">
  
            <!-- Project Card Exampl-mde -->
            <div class="card shadow cardProgressPieChart">
              <div class="card-header dashboard_card_header">
                <h6 class="m-0 font-weight-bold text-md-left text-lg-left text-center">PRICE GAINER</h6>
              </div>
              <div class="card-body">
                <div id="appearChart"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 pl-md-3 pl-xl-2 pl-lg-2">
  
            <!-- Project Card Exampl-mde -->
            <div class="card shadow cardProgressPieChart">
              <div class="card-header dashboard_card_header">
                <h6 class="m-0 font-weight-bold text-md-left text-lg-left text-center">PRICE LOSER</h6>
              </div>
              <div class="card-body">
                <div id="progressChart"></div>
              </div>
            </div>
          </div>
      </div>
     </div>
    </section>



    <section class="longBuildDet">
      <div class="container-fluid mt-3">
        <div class="row">
          <div class="col-xl-3 col-lg-3  pr-md-3 pr-lg-0 pr-xl-0 mb-md-3 mb-lg-0 mb-xl-0 mb-3 colDetfee">
  
            <!-- Project Card Exampl-mde -->
            <div class="card shadow cardProgressPieChart">
              <div class="card-header dashboard_card_header">
                <h6 class="m-0 font-weight-bold text-md-left text-lg-left text-center">LONGBUILD UP (Future)</h6>
              </div>
              <div class="card-body">
                <div id="progressChart1"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 pl-md-3 pr-md-3 pr-lg-0 pr-xl-0 mb-md-3 mb-lg-0 mb-xl-0 mb-3">
  
            <!-- Project Card Exampl-mde -->
            <div class="card shadow cardProgressPieChart">
              <div class="card-header dashboard_card_header">
                <h6 class="m-0 font-weight-bold text-md-left text-lg-left text-center">SHORTBUILD UP</h6>
              </div>
              <div class="card-body">
                <div id="progressChart3"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3  pr-md-3 pr-lg-0 pr-xl-0 mb-md-3 mb-lg-0 mb-xl-0 mb-md-0 mb-3">
  
            <!-- Project Card Exampl-mde -->
            <div class="card shadow cardProgressPieChart">
              <div class="card-header dashboard_card_header">
                <h6 class="m-0 font-weight-bold text-md-left text-lg-left text-center">LONG UNWINDING (Future)</h6>
              </div>
              <div class="card-body">
                <div id="progressChart4"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 pl-md-3 pl-xl-2 pl-lg-2">
  
            <!-- Project Card Exampl-mde -->
            <div class="card shadow cardProgressPieChart">
              <div class="card-header dashboard_card_header">
                <h6 class="m-0 font-weight-bold text-md-left text-lg-left text-center">SHORT COVERING</h6>
              </div>
              <div class="card-body">
                <div id="progressChart5"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="longBuildDet mt-3">
      <div class="container-fluid">
        <div class="row">

          <div class="col-xl-3 col-lg-3  pr-md-3 pr-lg-0 pr-xl-0 mb-md-3 mb-lg-0 mb-xl-0 mb-3 colDetfee">
  
            <!-- Project Card Exampl-mde -->
            <div class="card shadow cardProgressPieChart">
              <div class="card-header dashboard_card_header">
                <h6 class="m-0 font-weight-bold text-md-left text-lg-left text-center">OI INCREASING</h6>
              </div>
              <div class="card-body">
                <div id="progressChart6"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 pl-md-3 pr-md-3 pr-lg-0 pr-xl-0 mb-md-3 mb-lg-0 mb-xl-0 mb-3">
  
            <!-- Project Card Exampl-mde -->
            <div class="card shadow cardProgressPieChart">
              <div class="card-header dashboard_card_header">
                <h6 class="m-0 font-weight-bold text-md-left text-lg-left text-center">OI DECREASING</h6>
              </div>
              <div class="card-body">
                <div id="progressChart7"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3  pr-md-3 pr-lg-0 pr-xl-0 mb-md-3 mb-lg-0 mb-xl-0 mb-md-0 mb-3">
  
            <!-- Project Card Exampl-mde -->
            <div class="card shadow cardProgressPieChart">
              <div class="card-header dashboard_card_header">
                <h6 class="m-0 font-weight-bold text-md-left text-lg-left text-center">OPTION OI BUILDUP</h6>
              </div>
              <div class="card-body">
                <div id="progressChart8"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 pl-md-3 pl-xl-2 pl-lg-2">
  
            <!-- Project Card Exampl-mde -->
            <div class="card shadow cardProgressPieChart">
              <div class="card-header dashboard_card_header">
                <h6 class="m-0 font-weight-bold text-md-left text-lg-left text-center">OPTION OI UNWINDING</h6>
              </div>
              <div class="card-body">
                <div id="progressChart9"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
     <section class="newSessions">
       <span id="sessions"></span>
     </section>   

     <!-- <div id="dashboard_session_expired" class="dasbaord_expired">
      <div class="session-square">
      <p id="dasboardSessionModel"></p>
      <button type="submit" class="btn dashboardCancel mr-3">ok</button>
       </div>
       </div> -->

       <div class="card dasbaord_expired" id="dashboard_session_expired">
        <h5 class="card-header headerSession">Session Expired</h5>
        <div class="card-body session-square">
          <div class="d-flex clockDash">
            <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
            <i class="fa fa-sign-in" aria-hidden="true"></i>
            <p id="dasboardSessionModel"></p>
         </div>
         <div class="text-center">
             <button type="submit" class="btn dashboardCancel mr-3">ok</button>
             </div>
        </div>
      </div>
     <app-footer></app-footer>







