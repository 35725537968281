<app-navbar></app-navbar>


<section class="iv_side_navbar" id="iv-data-det">
    <section class="datatableDet mt-3" id="section_userTable">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="iv_table">
                    <div class="card cardDatatableDet shadow">
                       
                        <div class="table-responsive table_iv_symbol">
                         <table id="usertable" class="table table-striped">
                                <thead>
                                    <tr class="tablecolordetails">
                                        <th></th>

                                        <th class="d-flex symbol_filter">Symbol<input type="text"
                                                class="form-control input_symbol" id="filter1" onkeyup="searchNifty()"
                                                placeholder="Search symbol" title="Type in a name"></th>
                                        <th class="symbol_filter align-middle">LastUpdated</th>
                                        <th class="symbol_filter">LTP<input type="text"
                                                class="form-control input_symbol" id="ltp_search" custom_col_num="3"
                                                onkeyup="searchNewTable()" placeholder="Search LTP"><span
                                                class="tooltiptext">search with > or < </span>
                                        </th>
                                        <th class="d-none">Previous</th>
                                        <th class="symbol_filter">IV<input type="text" class="form-control input_symbol"
                                                id="iv_search" custom_col_num="5" onkeyup="searchNewTable()"
                                                placeholder="Search IV" title="Type in a name"><span
                                                class="tooltiptext">search with > or < </span>
                                        </th>
                                        <th class="d-none">Previv</th>
                                 <th class="ivp_ivr align-middle">52 WEEKS (IVP/IVR)
                                     <div class="search_input_text">
                                    <div class="card fifty_two_weeks_card">
                                    <input type="text" class="form-control firstinput" id="fiftytwo_weeks"
                                          custom_col_num="7" onkeyup="searchWeekMonths()" placeholder="Search with > <"
                                          title="Type in a name">

                                    <input class="form-control fiftytwo firstinput mt-2" type="text" id="fiftytwo_slash"
                                          custom_col_num="7" onkeyup="searchAfterSlash()" placeholder="Search with > <"
                                          title="Type in a name">
                                    </div>
                                    </div>
                                         
                                </th>

                                        <th class="d-none">Previv</th>
                                        <th class="six_months_ivp align-middle">6 MONTHS (IVP/IVR)
                                            <div class="search_six_month_input_text">
                                                <div class="card fifty_two_weeks_card">
                                                    <input type="text" class="form-control firstinput" id="six_months"
                                                    custom_col_num="9" onkeyup="searchWeekMonths()" placeholder="Search with > <"
                                                    title="Type in a name">
                                                <input class="form-control fiftytwo firstinput mt-2" type="text" id="sixmonths_slash"
                                                    custom_col_num="9" onkeyup="searchAfterSlash()" placeholder="Search with > <"
                                                    title="Type in a name">
                                                </div>
                                                </div>
                                            </th>
                                        <th class="d-none">Previv</th>
                                        <th class="three_ivp_ivr align-middle">3 MONTHS (IVP/IVR)
                                            <div class="search_three_month_input_text">
                                                <div class="card fifty_two_weeks_card">
                                                    <input type="text" class="form-control firstinput" id="three_months"
                                                    custom_col_num="11" onkeyup="searchWeekMonths()"
                                                    placeholder="Search with > <" title="Type in a name">
                                                <input class="form-control fiftytwo firstinput mt-2" type="text"
                                                    id="three_months_slash" custom_col_num="11" onkeyup="searchAfterSlash()"
                                                    placeholder="Search with > <" title="Type in a name">
                                                </div>
                                                </div>
                                        </th>
                                        <th class="d-none">Previv</th>
                                        <th class="one_ivp_ivr align-middle">1 MONTH (IVP/IVR)
                                            <div class="search_one_month_input_text">
                                                <div class="card one_two_weeks_card">
                                                <input type="text" class="form-control firstinput" id="one_month"
                                                    custom_col_num="13" onkeyup="searchWeekMonths()" placeholder="Search with > <"
                                                    title="Type in a name">
                                                <input class="form-control fiftytwo firstinput mt-2" type="text" id="onemonth_slash"
                                                    custom_col_num="13" onkeyup="searchAfterSlash()"
                                                    placeholder="Search with > <" title="Type in a name">
                                                </div>
                                                </div>
                                        </th>
                                        <th class="d-none">Previv</th>
                                    </tr>
                                </thead>
                            </table>
                            <table id="searchtable" style="display:none">
                                <thead class="bg-dark text-white">
                                    <tr>
                                        <th></th>
                                        <th>Symbol</th>
                                        <th>LastUpdated</th>
                                        <th>LTP</th>
                                        <th class="d-none">Previous</th>
                                        <th>IV</th>
                                        <th class="d-none">Previv</th>

                                        <th>52 WEEKS</th>
                                        <th class="d-none">Previv</th>
                                        <th>6 MONTHS</th>
                                        <th class="d-none">Previv</th>
                                        <th>3 MONTHS</th>
                                        <th class="d-none">Previv</th>
                                        <th>1 MONTH</th>
                                        <th class="d-none">Previv</th>
                                    </tr>
                                </thead>

                            </table>

                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
</section>


<div class="card iv_expired" id="iv_session_expired">
    <h5 class="card-header headerSession">Session Expired</h5>
    <div class="card-body session-square">
        <div class="d-flex clockDash">
            <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
            <i class="fa fa-sign-in" aria-hidden="true"></i>
            <p id="ivSessionModel"></p>
        </div>
        <div class="text-center">
            <button type="submit" class="btn ivCancel mr-3">ok</button>
        </div>
    </div>
</div>
<div class="spinner"><!-- Place at bottom of page --></div>
<app-footer></app-footer>