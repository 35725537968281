import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(
    private httpClient:HttpClient
  ) { }

  login(data){
    return this.httpClient.post('https://beta.deltacapitis.com/memberapi/api/login',data);
  }
}
