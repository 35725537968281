import { Component, OnInit } from '@angular/core';
declare var getStrategy,strategyTimer,hidetimer: any;
@Component({
  selector: 'app-strategybuilder',
  templateUrl: './strategybuilder.component.html',
  styleUrls: ['./strategybuilder.component.css']
})
export class StrategybuilderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void 
  {
    getStrategy();
    hidetimer();
  }
    ngAfterContentInit(): void 
  {
    strategyTimer()
    
  }
  ngOnDestroy() 
  {
    stop()
  }
}