function format(d) {
  return '<table class="ivchartsymbol">' +
    '<tr class="notrdetails">' +
    '<td>' +
    '<section>' +
    '<div class="container-fluid">' +
    '<div class="row">' +
    '<div class="col-xl-12">' +
    // '<div class="card">' +
    // '<div class="card-body m-auto">' +
    '<form  class="form-inline justify-content-center" onsubmit="optionivchartButtonLoad(event)">' +
    // '<div class="container ml-md-5 ml-lg-5">' +
    // '<div class="row">' +
    // ' <div class="colform col-md-3 p r-2">' +
    '<label class="text-muted mr-md-2 mr-lg-2 mr-xl-2" for="sel1">Select 1st Index:</label>' +
    '<input  class="custom-select my-select" type="text" value="' + d.Symbol + '" id="symbol" name="chartNifty" readonly>' +
    // '<select class="custom-select my-select" id="sel" name="chartNifty">' +
    // '<option value=' + d.Symbol + '>' + d.Symbol + '</option>' +
    //  '<option value="BANKNIFTY">BANKNIFTY</option>'+
    // '</select>' +
    // '</div>' +
    // '<div class="colform col-md-3 pr-2">' +
    '<label class="text-muted ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2" for="sel1">From:</label>' +
    '<input id="fromdate" class="custom-select my-select" type="date" value="2021-04-05" name="fromdate">' +
    // '</div>' +
    // '<div class="colform col-md-3 pr-2">' +
    '<label class="text-muted ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2" for="sel1">To:</label>' +
    '<input id="todate" class="custom-select my-select" type="date" name="todate">' +
    // '</div>' +
    // '<div class="colform col-md-3 mt-3">' +
    '<input type="submit" class="btn btn_iv_analysis font-weight-bold ml-md-2 ml-lg-2 ml-xl-2" id="myBtn" value="Show Chart">' +
    // '</div>' +
    // '</div>' +
    // '</div>' +
    '</form>' +
    '<div class="card shadow cardAreaPieChart mt-2">' +

    // '<div class="card-header py-3">' +
    // '<h6 class="m-0 font-weight-bold text-primary">IV CHART</h6>' +
    // '</div>' +
    '<div class="card-body">' +
    '<div id="ivchartdetails"></div>' +
    // '</div>' +
    // '</div>' +
    '</div>' +
    '</div>' +
    '</td>' +
    '</tr>' +
    '</table>';
}
function newivDatatable() 
{
  $body = $("body");
  // document.getElementById("strategyCard").style.display = "block";
  $body.addClass("loading");
  var dcsessions = localStorage.getItem("session");
  $.ajax({
    url: 'https://beta.deltacapitis.com/memberapi/api/dc',
    type: "post",
    data: { id: "6", version: "1", session: dcsessions },
    success: function (response) {
      var d = new Date(); // for now
      d.getHours(); // => 9
      d.getMinutes(); // =>  30
      d.getSeconds();
      console.log('INPUTREQUEST',d);
      const scriptTag = document.createElement("script");
      scriptTag.append("var ivdata = " + response.data + ";");
      document.body.appendChild(scriptTag);

      addingivtable(ivdata);
      // var table = $('#usertable').DataTable({
      //   responsive: true,
      //   data: ivdata,
      //   sDom: 'lrtip',
      //   // "bLengthChange": false,
      //   // "bInfo" : false,
      //   // "bPaginate": false,
      //   //data : response,
      //   // "columnDefs": [
      //   //   {className:"minstock","targets":[3]},
      //   //   {className:"newclass","targets":[4]}
      //   // ],
      //   "aLengthMenu": [[5, 10, 15, 25, 100 -1], [5, 10, 15, 25, 100, "All"]],
      //   "iDisplayLength": 15,
      //   bAutoWidth: false, 
      //   columnDefs: [
      //     { className: "minstock1", targets: [3, 5] },
      //     { className: "prevsstock", targets: [4, 6, 8, 10, 12, 14] },
      //     { className: "test2", targets: [7, 9, 11, 13] },
      //     { width: "128px", "targets": [1,2, 3, 5] }
      //     //  { className: "test3", targets: [9] },
      //     //  { className: "test4", targets: [13] },
      //   ],
   
      //   columns: [
      //     {
      //       "className": 'details-control',
      //       "orderable": false,
      //       "data": null,
      //       "deferRender": true,
      //       "targets": 'no-sort',
      //       // 'targets': [1, 2, 3, 4, 5],
      //       "defaultContent": ''
      //     },

      //     { "data": "Symbol" },
      //     { "data": "LastUpdated" },
      //     { "data": "LTP" },
      //     { "data": "PrevClose" },
      //     { "data": "IV" },
      //     { "data": "PrevIV" },
      //     { "data": "Weeks52" },
      //     { "data": "Weeks52LowHigh" },
      //     { "data": "Months6" },
      //     { "data": "Months6LowHigh" },
      //     { "data": "Months3" },
      //     { "data": "Months3LowHigh" },
      //     { "data": "Month1" },
      //     { "data": "Month1LowHigh" },
      //     // {"data" : "Level"}              
      //   ],
        
      //   drawCallback: function () {
      //     $('.paginate_button:not(.disabled)', this.api().table().container())
      //       .on('click', function () {
      //         tert();
      //       });
      //     $('select:not(.disabled)', this.api().table().container())
      //       .on('click', function () {
      //         tert();
      //       });
      //     $('.sorting:not(.disabled)', this.api().table().container())
      //       .on('click', function () {
      //         tert();
      //       });

      //   },
        
      //   "order": [[1, 'asc']]
      // });
      // // table.page.len(-1).draw();
      // tert();
      // // table.page.len(-1).draw(false);

      // $('#usertable tbody tr td.details-control').on('click', function () {



      //   var tr = $(this).closest('tr');
      //   var row = table.row( tr );
 
      //   if ( row.child.isShown() ) {
      //       // This row is already open - close it
      //       row.child.hide();
      //       tr.removeClass('shown');
      //   }
      //   else {
      //     if ( table.row('.shown').length ) {
      //       $('.details-control', table.row('.shown').node()).click();
      //   }
      //       // Open this row
      //       row.child( format(row.data()) ).show();
      //       tr.addClass('shown');
      //       datapickerfromdate();
      //       datapicker();
      //       optionivchartdisplay();
            
      //   }
      // });
      // var d = new Date(); // for now
      // d.getHours(); // => 9
      // d.getMinutes(); // =>  30
      // d.getSeconds();
      // console.log('forTable',d);
      $body.removeClass("loading");
      // newtable(ivdata);
    },
    error: function (error) {
      $body.removeClass("loading");
      var erroriv=JSON.parse(error.responseText);
      if(erroriv.errorcode == '1000')
      {
        document.getElementById('ivSessionModel').innerHTML = "Please login again";
        var modalSquare = document.getElementById('iv_session_expired');
        modalSquare.style.display = 'block';
        // localStorage.clear();
        // var url = "https://deltacapitis.com/member/";
        // location.href = url;
        // logoutDashboard();
      }
      $(".ivCancel").on('click', function () 
      {
        localStorage.clear();
        var url = "https://deltacapitis.orangedigitalmedia.in/";
        location.href = url;
        // var modal = document.getElementById('dashboard_session_expired');
        // modal.style.display = "none";
   
        // // $(this).closest('tr').remove();
        // console.log(deleteDet);
        // $tds = positionIndex.find("td");
        // $.each($tds, function () {
        //   //  console.log($(this).attr('id'));              // Visits every single <td> element
        //   // console.log($(this).text());        // Prints out the text within the <td>
        // });
      });
    
    // var alteredURL = removeParam("/dashboard", url);
    // let params = new URLSearchParams(url.search);
    // params.delete('/beta');
    // console.log(params);
    // var tech = url.replace(/\/+$/, '');
    
    // location.href = "/login";
    }
  });

};

function addingivtable()
{
  var table = $('#usertable').DataTable({
    responsive: true,
    data: ivdata,
    sDom: 'lrtip',
    // "bLengthChange": false,
    // "bInfo" : false,
    // "bPaginate": false,
    //data : response,
    // "columnDefs": [
    //   {className:"minstock","targets":[3]},
    //   {className:"newclass","targets":[4]}
    // ],
    "aLengthMenu": [[5, 10, 15, 25, 100 -1], [5, 10, 15, 25, 100, "All"]],
    "iDisplayLength": 15,
    bAutoWidth: false, 
    columnDefs: [
      { className: "minstock1", targets: [3, 5] },
      { className: "prevsstock", targets: [4, 6, 8, 10, 12, 14] },
      { className: "test2", targets: [7, 9, 11, 13] },
      { width: "128px", "targets": [1,2, 3, 5] }
      //  { className: "test3", targets: [9] },
      //  { className: "test4", targets: [13] },
    ],

    columns: [
      {
        "className": 'details-control',
        "orderable": false,
        "data": null,
        "deferRender": true,
        "targets": 'no-sort',
        // 'targets': [1, 2, 3, 4, 5],
        "defaultContent": ''
      },

      { "data": "Symbol" },
      { "data": "LastUpdated" },
      { "data": "LTP" },
      { "data": "PrevClose" },
      { "data": "IV" },
      { "data": "PrevIV" },
      { "data": "Weeks52" },
      { "data": "Weeks52LowHigh" },
      { "data": "Months6" },
      { "data": "Months6LowHigh" },
      { "data": "Months3" },
      { "data": "Months3LowHigh" },
      { "data": "Month1" },
      { "data": "Month1LowHigh" },
      // {"data" : "Level"}              
    ],
    
    drawCallback: function () {
      $('.paginate_button:not(.disabled)', this.api().table().container())
        .on('click', function () {
          tert();
          
        });
      $('select:not(.disabled)', this.api().table().container())
        .on('click', function () {
          tert();
        });
      $('.sorting:not(.disabled)', this.api().table().container())
        .on('click', function () {
          tert();
        });

    },
    
    "order": [[1, 'asc']]
  });
  // table.page.len(-1).draw();
  tert();
  // table.page.len(-1).draw(false);
  $('#usertable tbody').on('click', 'td.details-control', function () {
  // $('#usertable tbody tr td.details-control').on('click', function () {



    var tr = $(this).closest('tr');
    var row = table.row( tr );

    if ( row.child.isShown() ) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass('shown');
    }
    else {
      if ( table.row('.shown').length ) {
        $('.details-control', table.row('.shown').node()).click();
    }
        // Open this row
        row.child( format(row.data()) ).show();
        tr.addClass('shown');
        datapickerfromdate();
        datapicker();
        optionivchartdisplay();
        
    }
  });
  // $body.removeClass("loading");
}

function searchNewTable() {

  var ltp_search = ((document.getElementById("ltp_search").value).length != 0) ? (document.getElementById("ltp_search").value) : 0;
  var ltp_column_num = document.getElementById("ltp_search").getAttribute("custom_col_num");

  var iv_search = ((document.getElementById("iv_search").value).length != 0) ? (document.getElementById("iv_search").value) : 0;
  var iv_column_num = document.getElementById("iv_search").getAttribute("custom_col_num");

  if(ltp_search != 0 || iv_search != 0 ){
    $("#usertable").DataTable().page.len(-1).draw();
    // tert();
  } else {
    $("#usertable").DataTable().page.len(25).draw();
  }

  var column_num;
  var searchData;

  if(ltp_search != 0){
    column_num = ltp_column_num;
    searchData = ltp_search;
  }
  if(iv_search != 0){
    column_num = iv_column_num;
    searchData = iv_search;
  }


  console.log(column_num, searchData);

  var filter = searchData.replace(/\s*/g, '');
 
  // if(ltp_search.length != 0){
  //   $("#usertable").hide();
  //   $('#usertable').parents('div.dataTables_wrapper').first().hide();
  //   $("#searchtable").show();
  //   $('#searchtable').parents('div.dataTables_wrapper').first().show();
  // }

  if (filter.match(/^<\d+$/)) {
    var num = filter.match(/\d+/);
    console.log(num);
    num = parseInt(num, 10);
    console.log("entered 1st", num);
      
    $('#usertable tbody tr').each(function () {
        var allRows = parseFloat($('td:eq('+column_num+')', this).text()) || 0;
        console.log(allRows);  
    
      if (allRows < num) {
        $(this).show()
        // var rowData = $(this);
        // $('#searchtable tr:last').after(rowData);
      }
      else {
        $(this).hide()
      }

    });
    
  }
  else if (filter.match(/^>\d+$/)) {
    var num = filter.match(/\d+/);
    console.log(num);
    num = parseInt(num, 10);
    console.log("entered 1st", num);
      
    $('#usertable tbody tr').each(function () {
        var allRows = parseFloat($('td:eq('+column_num+')', this).text()) || 0;
        console.log(allRows);  
    
      if (allRows > num) {
        $(this).show()
        // var rowData = $(this);
        // $('#searchtable tr:last').after(rowData);
      }
      else {
        $(this).hide()
      }

    });
    
  }  
  else {
    $('#usertable tbody tr').each(function () {
      $(this).show();
    });    
  }

  

}

function searchWeekMonths() {
  var week_search = ((document.getElementById("fiftytwo_weeks").value).length != 0) ? (document.getElementById("fiftytwo_weeks").value) : 0;
  var week_column_num = document.getElementById("fiftytwo_weeks").getAttribute("custom_col_num");

  var six_months = ((document.getElementById("six_months").value).length != 0) ? (document.getElementById("six_months").value) : 0;
  var six_months_num = document.getElementById("six_months").getAttribute("custom_col_num");

  var one_months = ((document.getElementById("one_month").value).length != 0) ? (document.getElementById("one_month").value) : 0;
  var one_months_num = document.getElementById("one_month").getAttribute("custom_col_num");

  var three_months = ((document.getElementById("three_months").value).length != 0) ? (document.getElementById("three_months").value) : 0;
  var three_months_num = document.getElementById("three_months").getAttribute("custom_col_num");

  // var iv_search = ((document.getElementById("iv_search").value).length != 0) ? (document.getElementById("iv_search").value) : 0;
  // var iv_column_num = document.getElementById("iv_search").getAttribute("custom_col_num");

  if(week_search != 0 || six_months !=0 || three_months !=0  || one_months !=0 )
  {
    $("#usertable").DataTable().page.len(-1).draw();
    tert();
  } 
  else 
  {
    $("#usertable").DataTable().page.len(25).draw();
  }

  var column_num;
  var searchData;

  if(week_search != 0)
  {
    column_num = week_column_num;
    searchData = week_search;
  }
  if(six_months != 0)
  {
    column_num = six_months_num;
    searchData = six_months;
  }
  if(three_months != 0)
  {
    column_num = three_months_num;
    searchData = three_months;
  }

  if(one_months != 0)
  {
    column_num = one_months_num;
    searchData = one_months;
  }


  console.log(column_num, searchData);

  var filter = searchData.replace(/\s*/g, '');
 
  // if(ltp_search.length != 0){
  //   $("#usertable").hide();
  //   $('#usertable').parents('div.dataTables_wrapper').first().hide();
  //   $("#searchtable").show();
  //   $('#searchtable').parents('div.dataTables_wrapper').first().show();
  // }

  if (filter.match(/^<\d+$/)) {
    var num = filter.match(/\d+/);
    // var num = filter.match(/\d+/);
    var number = parseFloat(num.input.split("<")[1]);
    // console.log(number);
    // num = parseInt(num, 10);
    console.log("entered 1st", num);
      
    $('#usertable tbody tr').each(function () {
        var allRows = ($('td:eq('+column_num+')', this).text()) || 0;
        // var allRows = parseFloat($('td:eq('+column_num+')', this).split("/")[1]);
        // console.log(allRows);  
        var numerator = parseFloat(allRows.split("/")[0]);
    
      if (numerator < number) {
        $(this).show()
        // var rowData = $(this);
        // $('#searchtable tr:last').after(rowData);
      }
      else {
        $(this).hide()
      }

    });
    
  }
  
  else if (filter.match(/^>\d+$/)) {
    var num = filter.match(/\d+/);
    // var num = filter.match(/\d+/);
    var number = parseFloat(num.input.split(">")[1]);
    // console.log(number);
    // num = parseInt(num, 10);
    console.log("entered 1st", num);
      
    $('#usertable tbody tr').each(function () {
        var allRows = ($('td:eq('+column_num+')', this).text()) || 0;
        // var allRows = parseFloat($('td:eq('+column_num+')', this).split("/")[1]);
        // console.log(allRows);  
        var numerator = parseFloat(allRows.split("/")[0]);
    
      if (numerator > number) {
        $(this).show()
        // var rowData = $(this);
        // $('#searchtable tr:last').after(rowData);
      }
      else {
        $(this).hide()
      }

    });
    
  }

  else {
    $('#usertable tbody tr').each(function () {
      $(this).show();
    });    
  }
}
function searchAfterSlash()
{
  var week_search = ((document.getElementById("fiftytwo_slash").value).length != 0) ? (document.getElementById("fiftytwo_slash").value) : 0;
  var week_column_num = document.getElementById("fiftytwo_slash").getAttribute("custom_col_num");

  var six_months = ((document.getElementById("sixmonths_slash").value).length != 0) ? (document.getElementById("sixmonths_slash").value) : 0;
  var six_months_num = document.getElementById("sixmonths_slash").getAttribute("custom_col_num");

  var one_months = ((document.getElementById("onemonth_slash").value).length != 0) ? (document.getElementById("onemonth_slash").value) : 0;
  var one_months_num = document.getElementById("onemonth_slash").getAttribute("custom_col_num");

  var three_months = ((document.getElementById("three_months_slash").value).length != 0) ? (document.getElementById("three_months_slash").value) : 0;
  var three_months_num = document.getElementById("three_months_slash").getAttribute("custom_col_num");

  // var iv_search = ((document.getElementById("iv_search").value).length != 0) ? (document.getElementById("iv_search").value) : 0;
  // var iv_column_num = document.getElementById("iv_search").getAttribute("custom_col_num");

  if(week_search != 0 ){
    $("#usertable").DataTable().page.len(-1).draw();
    tert();
  } else {
    $("#usertable").DataTable().page.len(25).draw();
  }

  // function task()
  // {
  //   var updateddata = searchWeekMonths();
  //   if (updateddata != null) {
  //     searchAfterSlash();
  //   }
  //   else {
  //     searchAfterSlash();
  //   }
  // }
  var column_num;
  var searchData;

  if(week_search != 0){
    column_num = week_column_num;
    searchData = week_search;
  }
  if(six_months != 0)
  {
    column_num = six_months_num;
    searchData = six_months;
  }
  if(three_months != 0)
  {
    column_num = three_months_num;
    searchData = three_months;
  }

  if(one_months != 0)
  {
    column_num = one_months_num;
    searchData = one_months;
  }
  // if(iv_search != 0){
  //   column_num = iv_column_num;
  //   searchData = iv_search;
  // }


  console.log(column_num, searchData);

  var filter = searchData.replace(/\s*/g, '');
 
  // if(ltp_search.length != 0){
  //   $("#usertable").hide();
  //   $('#usertable').parents('div.dataTables_wrapper').first().hide();
  //   $("#searchtable").show();
  //   $('#searchtable').parents('div.dataTables_wrapper').first().show();
  // }

  if (filter.match(/^<\d+$/)) {
    var num = filter.match(/\d+/);
    // var num = filter.match(/\d+/);
    var number = parseFloat(num.input.split("<")[1]);
    // console.log(number);
    // num = parseInt(num, 10);
    console.log("entered 1st", num);
      
    $('#usertable tbody tr').each(function () {
      var allRows = ($('td:eq('+column_num+')', this).text()) || 0;
      // var allRows = parseFloat($('td:eq('+column_num+')', this).split("/")[1]);
      // console.log(allRows);  
      var numerator = parseFloat(allRows.split("/")[1]);
    
      if (numerator < number) {
        $(this).show()
        // var rowData = $(this);
        // $('#searchtable tr:last').after(rowData);
      }
      else {
        $(this).hide()
      }

    });
    
  }  
  else if (filter.match(/^>\d+$/)) {
    var num = filter.match(/\d+/);
    // var num = filter.match(/\d+/);
    var number = parseFloat(num.input.split(">")[1]);
    // console.log(number);
    // num = parseInt(num, 10);
    console.log("entered 1st", num);
      
    $('#usertable tbody tr').each(function () {
      var allRows = ($('td:eq('+column_num+')', this).text()) || 0;
   
      var numerator = parseFloat(allRows.split("/")[1]);
    
      if (numerator > number) {
        $(this).show()
     
      }
      else {
        $(this).hide()
      }

    });
    
  }  
  else {
    $('#usertable tbody tr').each(function () {
      $(this).show();
    });    
  }
}

function optionivchartButtonLoad(event) {
  event.preventDefault();
  var dcsessions = localStorage.getItem("session");
  $.ajax({
    type: "POST",
    url: "https://beta.deltacapitis.com/memberapi/api/dc",
    data: { id: "8", version: "1", session: dcsessions, symbol: event.target.chartNifty.value, fromdate: event.target.fromdate.value, todate: event.target.todate.value },
    success: function (response) {
      console.log(response.data);
      const scriptTag = document.createElement("script");
      scriptTag.append("var ivchartdetApi = " + response.data + ";");
      document.body.appendChild(scriptTag);
      var strikeTemp = window.ivchartdetApi;
      console.log(strikeTemp);
      if (jQuery('#ivchartdetails').html('')) {
        ivsymbolChart(strikeTemp);
        jQuery('script').html('')
      }

    },
    error: function (error) {
      console.log("error in error: " + error.message);
      localStorage.clear();
      // var url = "https://beta.deltacapitis.com/beta/";
      // location.href = url+"/login";
      // var alteredURL = removeParam("/dashboard", url);
      // let params = new URLSearchParams(url.search);
      // params.delete('/beta');
      // console.log(params);
      // var tech = url.replace(/\/+$/, '');
     
      // location.href = "/login";
    }
  });

}
function datapickerfromdate() {
  var now1 = new Date();
  var day1 = ("0" + now1.getDate()).slice(-2);
  var month1 = ("0" + (now1.getMonth() + 1)).slice(-2);
  var today1 = now1.getFullYear() + "-" + (month1) + "-" + (day1);
  $('#todate').val(today1);
};
function datapicker() {
  var now = new Date();
  var day = ("0" + now.getDate()).slice(-2);
  var month = ("0" + (now.getMonth() - 2)).slice(-2);
  var today = now.getFullYear() + "-" + (month) + "-" + (day);
  $('#fromdate').val(today);
};
function optionivchartdisplay() {

  var dcsessions = localStorage.getItem("session");
  const form = {
    symbols: document.getElementById('symbol'),
    fromdate: document.getElementById('fromdate'),
    todate: document.getElementById('todate'),
  };
  console.log(form.symbols.value);
  console.log(form.fromdate.value);
  console.log(form.todate.value);
  $.ajax({
    type: "POST",
    url: "https://beta.deltacapitis.com/memberapi/api/dc",
    data: { id: "8", version: "1", session: dcsessions, symbol: form.symbols.value, fromdate: form.fromdate.value, todate: form.todate.value },
    success: function (response) {
      const scriptTag = document.createElement("script");
      scriptTag.append("var ivchartdetApi2 = " + response.data + ";");
      document.body.appendChild(scriptTag);
      var strikeTemp = window.ivchartdetApi2;
      console.log(strikeTemp);
      if (jQuery('#ivchartdetails').html('')) {
        ivsymbolChart(strikeTemp);
        jQuery('script').html('')
      }

    },
    error: function (error) {
      console.log("error in error: " + error.message);
          //  var url = "https://beta.deltacapitis.com/beta/";
          //  location.href = url+"/login";
      // var alteredURL = removeParam("/dashboard", url);
      // let params = new URLSearchParams(url.search);
      // params.delete('/beta');
      // console.log(params);
      // var tech = url.replace(/\/+$/, '');
     
      // location.href = "/login";
    }
  });

}


function ivsymbolChart(strikeTemp) {
  if (typeof strikeTemp !== 'undefined') {
    var dataTable = anychart.data.table('Time');
    dataTable.addData(strikeTemp.IVDATA);
  
    var ohlcMapping = dataTable.mapAs({ x: 'Time', value: 'Price' });
    var lineMapping = dataTable.mapAs({ x: 'Time', value: 'IV' });
    var lineMapping2 = dataTable.mapAs({x: 'Time', value: 'HV'});
    // var lineMapping3 = dataTable.mapAs({x: 'Time', value: 'PCR45'});
    anychart.format.outputTimezone(-60);

    // create stock chart
    var chart = anychart.stock();

    xAxis = chart.plot(0).xAxis();

  
    chart.padding(0, 70, 30, 50);
    var title = chart.title();
    // enable title
    title.enabled(true);
    // set title text
    title.text(strikeTemp.Title);
    // set font size
    title.fontSize("18px");
    title.fontColor("black");
    // underline text
    // title.fontDecoration("'Baloo Bhai 2', cursive");
    // set font family
    title.fontFamily("'Baloo Bhai 2', cursive");
    // setting xScale settings

    // create the plot on the chart with column series
    var plot_line_ohlc = chart.plot(0);

    var extraYScale = anychart.scales.linear();
 
    var extraYAxis = plot_line_ohlc.yAxis(1);
    extraYAxis.orientation("right");
    extraYAxis.scale(extraYScale);

    var ohlcSeries = plot_line_ohlc.area(ohlcMapping);
    ohlcSeries.yScale(extraYScale);
    ohlcSeries.color("#d3e9f3");
    ohlcSeries.stroke('1.5 #a7d4e7');
    ohlcSeries.name("Price");
    var bearSeries = plot_line_ohlc.line(lineMapping2);
    bearSeries.name("HV");
    bearSeries.color("red");
    
    var lineSeries = plot_line_ohlc.line(lineMapping);
   
    lineSeries.name("IV");
    lineSeries.color("#001f3d");
   
    chart.container("ivchartdetails");
    chart.draw(true);
  }
  else {
    setTimeout(ivsymbolChart, 1000);
  }
}
 function searchNifty() {
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById("filter1");
  filter = input.value.toUpperCase();
  // console.log(filter);
  // var filter = $('#filter').val().replace(/\s*/g, '');
  table = document.getElementById("usertable");
  tr = table.getElementsByTagName("tr");
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[1];
    if (td) {
      txtValue = td.textContent || td.innerText;
      console.log(txtValue);
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
}


function tert() {
  [...document.querySelectorAll("td.minstock1")].forEach(function (ms) {
    var minStock = +ms.innerText, stock = +ms.closest("td").nextElementSibling.innerText;
    ms.classList.toggle("stock-empty", minStock > stock)
    if (minStock < stock) {
      ms.style.backgroundColor = "#ff8989b3";
      ms.style.color = "#333";
      ms.style.fontWeight = "900";
      ms.style.border = "none";
    }
    else {
      ms.style.backgroundColor = "#c4f4c4";

      ms.style.color = "#333";
      ms.style.fontWeight = "900";
      ms.style.border = "none";
    }
  });
  [...document.querySelectorAll("td.test2")].forEach(function (ms) {
    var minStock = ms.innerText, stock = ms.closest("td").nextElementSibling.innerText;
 
    res1 = parseInt(minStock);
    result1 = Math.floor(res1);

    res = parseInt(stock);
    result = Math.floor(res);

    if (result > result1) {
      ms.style.backgroundColor = "#ff8989b3";
      ms.style.color = "#333";
      ms.style.fontWeight = "900";
      ms.style.border = "none";
    }
    else {
      ms.style.backgroundColor = "#29e429";
     
      ms.style.color = "#333";
      ms.style.fontWeight = "900";
      ms.style.border = "none";
    }
  });
  [...document.querySelectorAll("td.test3")].forEach(function (ms) {
    var minStock = ms.innerText, stock = ms.closest("td").nextElementSibling.innerText;
   
    res1 = parseInt(minStock);
    result1 = Math.floor(res1);

    res = parseInt(stock);
    result = Math.floor(res);


    if (result > result1) {
      ms.style.backgroundColor = "#ff8989b3";
      ms.style.color = "#333";
      ms.style.fontWeight = "900";
      ms.style.border = "none";
    }
    else {
      ms.style.backgroundColor = "#c4f4c4";
  
      ms.style.color = "#333";
      ms.style.fontWeight = "900";
      ms.style.border = "none";
    }
  });
  [...document.querySelectorAll("td.test2")].forEach(function (ms) {
    var minStock = ms.innerText, stock = ms.closest("td").nextElementSibling.innerText;
    res1 = parseInt(minStock);
    result1 = Math.floor(res1);

    res = parseInt(stock);
    result = Math.floor(res);
    if (result > result1) {
      ms.style.backgroundColor = "#ff8989b3";
      ms.style.color = "#333";
      ms.style.fontWeight = "900";
      ms.style.border = "none";
    }
    else {
      ms.style.backgroundColor = "#c4f4c4";
      ms.style.color = "#333";
      ms.style.fontWeight = "900";
      ms.style.border = "none";
    }
  });
  [...document.querySelectorAll(".test4")].forEach(function (ms) {
    var minStock = ms.innerText, stock = ms.closest("td").nextElementSibling.innerText;
    res1 = parseInt(minStock);
    result1 = Math.floor(res1);
    res = parseInt(stock);
    result = Math.floor(res);

    if (result > result1) {
      ms.style.backgroundColor = "#ff8989b3";
      ms.style.color = "#333";
      ms.style.fontWeight = "900";
      ms.style.border = "none";
    }
    else 
    {
      ms.style.backgroundColor = "#c4f4c4";
      ms.style.color = "#333";
      ms.style.fontWeight = "900";
      ms.style.border = "none";
    }
  });

}
