import { Component, OnInit } from '@angular/core';
declare var indexMeterDataListApi,  sessionLoad,hideReload ,currentYear,  colorChange,apiCall : any;
@Component({
  selector: 'app-indexmeter',
  templateUrl: './indexmeter.component.html',
  styleUrls: ['./indexmeter.component.css']
})
export class IndexmeterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    indexMeterDataListApi();
    sessionLoad();
    hideReload();
    // currentYear();
    // indexmeterChart();
    // colorChange();
    // apiCall();
  }

}
