import { Component, OnInit } from '@angular/core';
declare var scalperDataListApi: any;
declare var sessionLoad,scalperTimer,sidenavbar,hidetimer : any;
@Component({
  selector: 'app-scaplerstrike',
  templateUrl: './scaplerstrike.component.html',
  styleUrls: ['./scaplerstrike.component.css']
})
export class ScaplerstrikeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $("#selectSymbol").change(function () { scalperDataListApi( $('#selectSymbol option:selected').text())});
   scalperDataListApi('');
    // hi();
    sessionLoad();
    hidetimer();
    // scalperTimer();
    // sidenavbar();
    // scalperloaddata();
    // myFunc();
    // take();
  }
  ngAfterContentInit(): void 
  {
    scalperTimer();
    
  }
  ngOnDestroy() {
    stop()
  }
  

}
