import { Component, OnInit } from '@angular/core';
declare var PcrDataListApi, sessionLoad,pcrTimer,hidetimer: any;
@Component({
  selector: 'app-pcr',
  templateUrl: './pcr.component.html',
  styleUrls: ['./pcr.component.css']
})
export class PcrComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    PcrDataListApi();
    sessionLoad();
    hidetimer();
  }
  ngAfterContentInit(): void 
  {
    pcrTimer();
  }
  ngOnDestroy() {
    stop()
  }

}
