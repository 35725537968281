import { Component, OnInit } from '@angular/core';
declare var volumeSPeaksDataListApi,setRadio,sessionLoad,hideReload;
@Component({
  selector: 'app-volumespeaks',
  templateUrl: './volumespeaks.component.html',
  styleUrls: ['./volumespeaks.component.css']
})
export class VolumespeaksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    volumeSPeaksDataListApi();
    setRadio();
    sessionLoad();
    hideReload();
  }

}
