<app-navbar></app-navbar>
<section class="scalper_side_navbar" id="scalper-data-det">
<!-- <section class="inputDetails mt-3">
  <div class="container-fluid">
      <div class="row">
          <div class="col-md-12 col-xl-12 col-lg-12">
              <div class="card cardInputDet shadow">
                  <div class="card-header py-3">
                    <div class="container">
                      
                      <h6 class="m-0 font-weight-bold text-primary">INPUT</h6>
                 
                      
                  </div>
                  </div>
                  <div class="card-body m-auto"> -->
               
              


                        <!-- <form class="form-inline" action="/action_page.php">
                          <label class="text-muted mr-md-2 mr-lg-2 mr-xl-2" for="sel1">Select Symbol:</label>
      
                          <select class="custom-select my-select" id="selectSymbol">
                        
                          </select>  
                          <label class="text-muted ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2" for="sel1">Select Date:</label>
              
                            <select class="custom-select my-select" id="selectDate">
                              <option id="items"></option>
                       
                          </select>
                          <label class="text-muted ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2" for="sel1">Select Strike:</label>
                          <select class="custom-select my-select" id="selectstrike">
                    
                          </select> 
                    
                          <button type="submit" onclick="scalperOnClick()" class="btn btn-primary font-weight-bold ml-md-2 ml-lg-2 ml-xl-2">Show Chart</button>
                        </form> -->
                  <!-- </div> -->
                  <!-- <div class="col-md-12"> -->
                    <!-- <div class="text-center scalperDet">
                    
                      <input type="submit" class="btn btnFirst border border-dark" id="myField3" onClick="myFunc(this.value);" value="">
                      <input type="submit" class="btn btnFirst border border-dark" id="myField2" onClick="myFunc(this.value);" value="">
                      <input type="submit" class="btn btnFirst border border-dark" id="myField1" onClick="myFunc(this.value);" value="">
                      <input type="submit" class="btn btnFirst border border-dark" id="myField" onClick="myFunc(this.value);" value="">
                      <input type="submit" class="btn btnFirst border border-dark" id="myField4" onClick="myFunc(this.value);" value="">
                      <input type="submit" class="btn btnFirst border border-dark" id="myField5" onClick="myFunc(this.value);" value="">
                      <input type="submit" class="btn btnFirst border border-dark" id="myField6" onClick="myFunc(this.value);" value="">
                      
                  </div> -->
                  <!-- </div>
          </div>
      </div>

  </div>
  </div>
</section> -->
<!-- <section class="mt-2">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadow">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row align-items-center d-flex justify-content-center">
                <div class="col-md-3 align-items-center d-flex justify-content-centeq">
                  <h6 class="scalperLastUpdated font-weight-bold mt-2">LAST UPDATED: <span id="scalperdateDetails"></span></h6> 
                </div>
                <div class="col-md-3 align-items-center d-flex justify-content-center">
           
              <div class="d-flex align-items-center">
                <h6 class="mt-2 ml-2 mr-2">Refresh In Sec:</h6>
                <div id="countdown">
                  <div id="scalper-timer"></div>
                  <svg>
                    <circle r="18" cx="20" cy="20"></circle>
                  </svg>
                </div>
              </div>
             </div>


             </div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<h6 class="scalperLastUpdated font-weight-bold mt-2 d-none">LAST UPDATED: <span id="scalperdateDetails"></span></h6>
<section class="firstChart mt-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
         <div class="card shadow cardAreaPieChart">
        <!-- Card Header - Dropdown -->


          <div class="card-header relative_card_header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 col-lg-1 col-xl-1">
                  <div id="sample">
                  <h5 class="vertical">SCALPER<span>&nbsp;DATA</span></h5>
                  </div>
                </div>
                <div class="col-md-12 col-lg-7 col-xl-7 m-auto">
                  <form class="form-inline float-md-none float-lg-right float-xl-right float-right" action="/action_page.php">
                    <label class="mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Symbol:</label>
      
                    <select class="custom-select my-select" id="selectSymbol">
         
                    </select>  
                    <label class="ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Date:</label>
        
                
                      <select class="custom-select my-select" id="selectDate">
                        <option id="items"></option>
                 
                    </select>
                    <label class="ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Strike:</label>
                    <select class="custom-select my-select" id="selectstrike">
              
                    </select> 
                    <button type="submit" onclick="scalperOnClick()" class="btn btnRelative ml-md-2 ml-lg-2 ml-xl-2 btninputfield ">Show Chart</button>
                  </form>
                </div>
                <div class="col-md-12 col-lg-4 col-xl-4 m-auto pl-lg-0 scalper_all_button_responsive">
                  <input type="submit" class="btn btnFirst" id="myField3" onClick="myFunc(this.value);" value="">
                  <input type="submit" class="btn btnFirst" id="myField2" onClick="myFunc(this.value);" value="">
                  <!-- <input type="submit" class="btn btnFirst border border-dark" onClick="myFunc(this.value);" value="14550"> -->
                  <input type="submit" class="btn btnFirst" id="myField1" onClick="myFunc(this.value);" value="">
                  <input type="submit" class="btn btnFirst" id="myField" onClick="myFunc(this.value);" value="">
                  <input type="submit" class="btn btnFirst" id="myField4" onClick="myFunc(this.value);" value="">
                  <input type="submit" class="btn btnFirst" id="myField5" onClick="myFunc(this.value);" value="">
                  <input type="submit" class="btn btnFirst" id="myField6" onClick="myFunc(this.value);" value="">
                </div>
              </div>
            </div>
      
           </div>
         <div class="card-body">
            <div id="scalperData"></div>
            <div id="scalperData1"></div>
            <div id="scalperData2"></div>
         </div>
           </div>
         </div>
       </div>
      </div>
</section>
<div id="app"></div>
<section class="newSessions">
    <span id="sessions"></span>
  </section> 
</section>

  
    <div class="card scal_expired" id="scal_session_expired">
      <h5 class="card-header headerSession">Session Expired</h5>
      <div class="card-body session-square">
        <div class="d-flex clockDash">
          <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
          <i class="fa fa-sign-in" aria-hidden="true"></i>
          <p id="scalSessionModel"></p>
       </div>
       <div class="text-center">
           <button type="submit" class="btn scalCancel mr-3">ok</button>
           </div>
      </div>
    </div>
    <div class="spinner"><!-- Place at bottom of page --></div>
  <app-footer></app-footer>
  <!-- <div class="multi-select-2"></div> -->

  <!-- <button type="button" id="changeValBtn">Select Tuesday</button> -->