<app-navbar></app-navbar>
<section class="oi_side_navbar" id="oi-data-det">
<!-- <section class="cardSeletOption mt-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadow">
          <div class="card-body align-items-center d-flex justify-content-center">
            <div class="container-fluid">
              <div class="row align-items-center d-flex justify-content-center">
                <div class="col-md-3 align-items-center d-flex justify-content-center">
                  <h6 class="oiDetails">LAST UPDATED: <span id="dateDetails"></span></h6> 
                </div>

                <div class="col-md-3 align-items-center d-flex justify-content-center">
          
              <div class="d-flex align-items-center">
            <h6 class="oiDetails">HIGHEST CALL VS PUT OI</h6>
            
        

              <p class="mr-2 ml-2">
                <label class="toggleSwitch nolabel" onclick="">
                    
                      <input type="checkbox" id="myCheck1">
                      <span>
                          <span>OFF</span>
                          <span>ON</span>
                      </span>
                      <a></a>
                  </label>
              </p>
              </div>
         
             </div>
             <div class="col-md-3 align-items-center d-flex justify-content-center">
              <div class="d-flex align-items-center">
              <h6 class="ml-2 oiDetails">% CHANGE IN HIGHEST CALL VS PUT OI</h6>
              


                  <p class="mr-2 ml-2">
                    <label class="toggleSwitch nolabel" onclick="">
                       
                          <input type="checkbox" id="myCheck2">
                        
                          <span>
                              <span>OFF</span>
                              <span>ON</span>
                          </span>
                          <a></a>
                      </label>
                  </p>
                </div>
             </div>
             <div class="col-md-3 align-items-center d-flex justify-content-center">
              <div class="d-flex align-items-center">
              <h6 class="ml-2 oiDetails">% AWAY FROM HIGHEST CALL VS PUT STRIKE</h6>
                  <p class="mr-2 ml-2">
                  <label class="toggleSwitch nolabel" onclick="">
                        <input type="checkbox" id="myCheck3">

                        <span>
                            <span>OFF</span>
                            <span>ON</span>
                        </span>
                        <a></a>
                    </label>
                </p>
                </div>
             </div>
             </div>
             </div>
        
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
 <section class="datatableDet mt-3">
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card cardDatatableDetOi shadow">
              <div class="card-header relative_card_header">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-2 col-lg-2 col-xl-2">
                      <div id="sample">
                      <h5 class="vertical">LAST UPDATED: <span id="dateDetails"></span></h5>
                      </div>
                    </div>
                    <div class="col-md-10 col-lg-10 col-xl-10 m-auto">
                      <div class="d-md-flex d-lg-flex d-xl-flex align-items-center float-md-right float-lg-right float-xl-right">
                        <div class="d-flex align-items-center">
                          <div id="sample">
                          <h6 class="verticalOi">HIGHEST CALL VS PUT OI</h6>
                          </div>
                            <p class="mr-2 ml-2">
                              <label class="toggleSwitch nolabel" onclick="">
                                    <!-- <input onclick="callHide()" type="checkbox"/> -->
                                    <input type="checkbox" id="myCheck1">
                                    <span>
                                        <span>OFF</span>
                                        <span>ON</span>
                                    </span>
                                    <a></a>
                                </label>
                            </p>
                          </div>
                          <div class="d-flex align-items-center">
                            <div id="sample">
                            <h6 class="pl-2 verticalOi">% CHANGE IN HIGHEST CALL VS PUT OI</h6>
                          </div>
                             <p class="mr-2 ml-2">
                                  <label class="toggleSwitch nolabel" onclick="">
                                        <!-- <input onclick="perCallHide()" type="checkbox"/> -->
                                        <input type="checkbox" id="myCheck2">
                                        <!-- <input type="checkbox" id="myCheck2" checked> -->
                                        <span>
                                            <span>OFF</span>
                                            <span>ON</span>
                                        </span>
                                        <a></a>
                                    </label>
                                </p>
                            </div>
                            <div class="d-flex align-items-center">
                              <div id="sample">
                              <h6 class="pl-2 verticalOi">% AWAY FROM HIGHEST CALL VS PUT STRIKE</h6>
                            </div>
                                  <p class="mr-2 ml-2">
                                  <label class="toggleSwitch nolabel" onclick="">
                                        <input type="checkbox" id="myCheck3">
                
                                        <span>
                                            <span>OFF</span>
                                            <span>ON</span>
                                        </span>
                                        <a></a>
                                    </label>
                                </p>
                                </div>
                      </div>
                    </div>
                  </div>
                </div>
          
               </div>
              <div class="table-responsive optionsData">

               

    <table class="table table-striped" id="tests">
      <colgroup>
          <col class="" />
          <col class="" />
          <col class="" />
          <col class="blue" span="2" />
          <col class="red" span="2" />
          <col class="dark" span="2" />
          <col class="orange" span="2" />
          <col class="yellow" span="2" />
          <col class="red" span="2" />
          <col class="blue" span="2" />
          <col class="red" span="2" />
          
        </colgroup>
      <thead>
      <tr class="oiTrDet">
      <th>Symbol</th>
      <!-- <th>LastUpdated</th> -->
      <th>Spot</th>
      <th>FUTURE</th>
      <th>HIGHEST CALL STRIKE</th>
      <th>HIGHEST PUT STRIKE</th>
      <th>% CHANGE IN CALL OI</th>

      <th>% CHANGE IN PUT OI</th>
      <th>HIGHEST CALL OI</th>
      <th>HIGHEST PUT OI</th>
      <th>% CHANGE IN HIGHEST CALL OI</th>
      
      <th>% CHANGE IN HIGHEST PUT OI</th>
      <th>% AWAY FROM HIGHEST CALL STRIKE</th>
   
      <th>% AWAY FROM HIGHEST PUT STRIKE</th>
   </tr>
      </thead>
      <tbody class="oiTbodyDet">
      </tbody>
      </table>
    </div>
    </div>
    </div>
    </div>
    </div>
    </section>
</section>
       <div class="card oi_expired" id="oi_session_expired">
        <h5 class="card-header headerSession">Session Expired</h5>
        <div class="card-body session-square">
          <div class="d-flex clockDash">
            <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
            <i class="fa fa-sign-in" aria-hidden="true"></i>
            <p id="oiSessionModel"></p>
         </div>
         <div class="text-center">
             <button type="submit" class="btn oiCancel mr-3">ok</button>
             </div>
        </div>
      </div>


 
<app-footer></app-footer>