<!-- <section class="newProfLogin">
  <div class="promodetails1">

    <div class="backgroundPromo2">
      <div class="card shadow cardBackground">
        <div class="cardbody">
          <h3 class="text-center"><img class="deltaimg" src="assets/images/logo.png" alt="Delta Capitis Logo"></h3>
        <form class="form-group">
        <ul id="form-messages" class="text-center"></ul>
        <div class="form-group">
        <label for="exampleFormControlSelect1">Username</label>
        <input type="email"  class=" form-control projectName mb-3" id="username" placeholder="Enter username"required>
        </div>
        <div class="form-group">
        <label for="exampleFormControlSelect1">Password</label>
        <input type="password" class="form-control inputLoginDet" id="password" placeholder="Enter password"required>
        </div>
        <div class="buttonSubmitDet">
         <button type="submit" id="btn-submit" class="btn btn-primary btnSubmitDet">LOGIN</button>
        </div>
        <div class="buttonsignup d-flex">
          <a href="https://deltacapitis.com/memberold/resetpassword.aspx" type="submit" id="btn-submit" class="btn btnforgot">Forgot password?</a>
        </div>
        </form>
 
        <div class="socialmediaicons text-center">
         
          <a href="https://twitter.com/deltacapitis"><img class="mr-4" src="assets/images/twitter.png" alt=""></a>
         <a href="https://t.me/DeltaCapitis"><img src="assets/images/telegram.png" alt=""></a> 
       </div>
       <div class="text-center">
        <a class="oldWebsite" href="https://deltacapitis.com/memberold/Default.aspx">For Old Website Click Here</a> 
     </div>
    </div>
 </div>
    </div>
  </div>
  <div class="promodetails">
    <div class="backgroundPromo">
    </div>
  </div>
</section>
 -->




<section class="newLoginForm">
  <div class="container">
    <div class="col-md-12 d-md-flex d-lg-flex d-xl-flex align-items-center justify-content-center">
      <div class="card login_form_det">
        <div class="card-body first_delta_card">
          <div class="text-left">
          <img class="deltaimg" src="assets/images/logo.png"  alt="Delta Capitis Logo" align="left">
          </div>
          <form class="form-group">
            <ul id="form-messages" class="text-center"></ul>
            <div class="form-group">
            <input type="email"  class=" form-control projectName mb-3" id="username" placeholder="Username"required>
            </div>
            <div class="form-group">
              <input type="password" class="form-control inputLoginDet" id="password" placeholder="Password"required>
            </div>
            <button type="submit" id="btn-submit" class="btn btnSubmitDet">Login</button>
            <!-- <div class="buttonsignup d-flex">
              <a href="https://deltacapitis.com/memberold/resetpassword.aspx" type="submit" id="btn-submit" class="btn btnforgot">Forgot password?</a>
            </div> -->
          </form>
          <div class="socialmediaicons text-center">
              <div class="iconDet pr-2">
              <a href="https://twitter.com/deltacapitis"><i class="fa fa-twitter" aria-hidden="true"></i></a>
              </div>
              <div class="iconDet">
             <a href="https://t.me/DeltaCapitis"><i class="fa fa-telegram" aria-hidden="true"></i></a> 
            </div>
           </div>
           <div class="text-center">
            <a class="oldWebsite" href="https://deltacapitis.com/memberold/Default.aspx">For Old Website Click Here</a> 
         </div>
        </div>
      </div>
      <div class="card marketBreadthDet">
        <img class="border_images" src="assets/images/border_box.png"  alt="Delta Capitis Logo">
        <img class="bottom_imageDet" src="assets/images/bottom_box.png"  alt="Delta Capitis Logo">
        <div class="card-body second_delta_card">
          <p>Turn your ideas<br>into reality..</p>
          <div id="carouselExampleIndicators" class="carousel slide market_login_carousel" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <h5 class="text-center">MARKET BREATH</h5>
              </div>
              <div class="carousel-item">
                <h5 class="text-center">OPTIONS</h5>
              </div>
              <div class="carousel-item">
                <h5 class="text-center">FUTURES</h5>
              </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


































