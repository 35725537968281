import { Component, OnInit } from '@angular/core';
declare var  optionPageInputLoad,sessionLoad,optionTimerChain,scalperTimer,hidetimer : any; 
@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  
    optionPageInputLoad();
    hidetimer();
    sessionLoad();
  }
  ngAfterContentInit(): void 
  {
    optionTimerChain();
    
  }
  ngOnDestroy() {
    stop()
  }

}
