<app-navbar></app-navbar>
<section class="eod_side_navbar" id="eod-data-det">
<section class="cardSeletOption mt-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card_toggle_det">
          <div class="card-body toggle_card_body align-items-center justify-content-center">
            <div class="container-fluid">
              <div class="row align-items-center justify-content-center">
             <div class="col-md-1 mr-2 d-flex col-6 align-items-center justify-content-center">
              <h6 class="ml-2 mr-1 oiDetails">Volume</h6>
              <p class="check_eod_charts">
                  <label class="toggleSwitch nolabel" onclick="">
               
                        <input type="checkbox" id="volume" checked>

                        <span>
                            <span>OFF</span>
                            <span>ON</span>
                        </span>
                        <a></a>
                    </label>
                </p>
                <!-- </div> -->
             </div>
             <div class="col-md-1 ml-3 mr-2 d-flex col-6 align-items-center justify-content-center">
              <h6 class="oiDetails mr-1">Delivery</h6>
               <p class="check_eod_charts">
                  <label class="toggleSwitch nolabel" onclick="">
                        <input type="checkbox" id="delivery" checked>
                        <span>
                            <span>OFF</span>
                            <span>ON</span>
                        </span>
                        <a></a>
                  </label>
                </p>
               </div>
               <div class="col-md-1 d-flex col-6 align-items-center justify-content-center">
                <h6 class="oiDetails mr-1 ml-lg-2">COI</h6>
                 <p class="check_eod_charts">
                    <label class="toggleSwitch nolabel" onclick="">
                          <input type="checkbox" id="coi" checked>
                          <span>
                              <span>OFF</span>
                              <span>ON</span>
                          </span>
                          <a></a>
                    </label>
                  </p>
                 </div>
             <div class="col-md-1 d-flex col-6 align-items-center justify-content-center">
              <h6 class="ml-2 mr-1 oiDetails">PCR</h6>
               <p class="check_eod_charts">
                  <label class="toggleSwitch nolabel" onclick="">
                    <input type="checkbox" id="pcr" checked>
                          <span>
                            <span>OFF</span>
                            <span>ON</span>
                          </span>
                          <a></a>
                  </label>
              </p>
                  <!-- </div> -->
             </div>
             <div class="col-md-1 d-flex col-6 align-items-center justify-content-center">
              <!-- <div class="d-flex align-items-center"> -->
              <h6 class="ml-2 mr-1 oiDetails">IV</h6>
                  
                <p class="check_eod_charts">
                  <label class="toggleSwitch nolabel" onclick="">
               
                        <input type="checkbox" id="iv" checked>

                        <span>
                            <span>OFF</span>
                            <span>ON</span>
                        </span>
                        <a></a>
                    </label>
                </p>
                <!-- </div> -->
             </div>
             <div class="col-md-1 d-flex col-6 align-items-center justify-content-center">
              <!-- <div class="d-flex align-items-center"> -->
              <h6 class="ml-2 mr-1 oiDetails">RSI</h6>
                  
                <p class="check_eod_charts">
                  <label class="toggleSwitch nolabel" onclick="">
               
                        <input type="checkbox" id="rsi">

                        <span>
                            <span>OFF</span>
                            <span>ON</span>
                        </span>
                        <a></a>
                    </label>
                </p>
                <!-- </div> -->
             </div>
             <div class="col-md-1 d-flex col-6 align-items-center justify-content-center">
              <!-- <div class="d-flex align-items-center"> -->
              <h6 class="ml-md-4 ml-lg-4 ml-xl-2 mr-1 oiDetails">STOCH</h6>
                  
                <p class="check_eod_charts">
                  <label class="toggleSwitch nolabel" onclick="">
               
                        <input type="checkbox" id="stock">

                        <span>
                            <span>OFF</span>
                            <span>ON</span>
                        </span>
                        <a></a>
                    </label>
                </p>
                <!-- </div> -->
             </div>
             </div>
             </div>
        
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="firstChart mt-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-lg-12">
       <div class="card shadow cardAreaPieChart">
      <!-- Card Header - Dropdown -->
         <!-- <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">
            FUTURES CUMULATIVE OI</h6>
         </div> -->


         <div class="card-header relative_card_header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-2 col-lg-1 col-xl-1">
                <div id="sample">
                <h5 class="vertical">FUTURES<span>&nbsp;CUMULATIVEOI</span></h5>
                </div>
              </div>
              <div class="col-md-6 col-lg-8 col-xl-6 m-auto pl-md-0 pl-lg-0 pl-xl-0">
                <form class="form-inline float-right" action="/action_page.php">
                  <label class="mr-md-2 mr-lg-2 mr-xl-2" for="sel1">Select Symbol:</label>
                  <select class="custom-select my-select" id="eodchartsSelect">
              
                  </select> 
                  <label class="mr-md-2 mr-lg-2 mr-xl-2 ml-lg-2 ml-xl-2" for="sel1">Select Year:</label>
                  <select class="custom-select my-select" id="YearEodNifty">
                   
                  </select>
                  <button type="submit" onclick="eodButtonLoad()" class="btn btnRelative font-weight-bold ml-md-2 ml-lg-2 ml-xl-2 btninputfield ">Show Chart</button>
                </form>
              </div>

            </div>
          </div>
    
         </div>

         <div class="card-body">
          <div id="eodData"></div>
         </div>
         </div>
       </div>
     </div>
    </div>
</section>
</section>

   <div class="card charts_expired" id="charts_session_expired">
    <h5 class="card-header headerSession">Session Expired</h5>
    <div class="card-body session-square">
      <div class="d-flex clockDash">
        <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
        <i class="fa fa-sign-in" aria-hidden="true"></i>
        <p id="chartsSessionModel"></p>
     </div>
     <div class="text-center">
         <button type="submit" class="btn chartCancel mr-3">ok</button>
         </div>
    </div>
  </div>
  <div class="spinner"><!-- Place at bottom of page --></div>

  <app-footer></app-footer>