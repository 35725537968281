import { Component, OnInit } from '@angular/core';
declare var ivDataListApi,newivDatatable,hideReload,datapicker,optionivchartdisplay,ivchartSymbolDataListApi,tableData,sessionLoad, getValue,colorChange: any;
declare var  start : any; 
@Component({
  selector: 'app-option-analysis',
  templateUrl: './option-analysis.component.html',
  styleUrls: ['./option-analysis.component.css']
})  
export class OptionAnalysisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // ivDataListApi();
    
    start();
    newivDatatable();
  
    // optionivchartdisplay();
    // datapicker();
    // ivchartSymbolDataListApi();
    // myFunction();
    sessionLoad();
    hideReload();
    // getValue();
    // colorChange();
    // tableData();
  }

}
