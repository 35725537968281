import { Component, OnInit } from '@angular/core';
declare var oiDataListApi,sessionLoad,hideReload: any;
declare var  start : any;
@Component({
  selector: 'app-optionoi',
  templateUrl: './optionoi.component.html',
  styleUrls: ['./optionoi.component.css']
})
export class OptionoiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // start();
    oiDataListApi();
    sessionLoad();
    hideReload();
  }

}
