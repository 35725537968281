<app-navbar></app-navbar>
<section class="optionss_side_navbar" id="optionss-data-det">
  <div id="lp-pom-image-288"><div class="loader"></div></div>
  <section class="mt-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow bear_bull_card">
                    <!-- Card Header - Dropdown -->
          <div class="card-header relative_card_header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 col-lg-2 col-xl-2">
                  <div id="sample">
                    <h5 class="vertical">BEAR / BULL OI CHANGE</h5>
                  </div>
                </div>
                <div class="col-md-10 col-lg-8 col-xl-8 m-auto">
                  <form class="form-inline options_form" action="/action_page.php">
                    <label class="mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Symbol:</label>
                    <select class="custom-select my-select" id="optionpagesymbol">
              
                    </select>
                    <label class="ml-md-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Date:</label>
                    <select class="custom-select my-select" id="OptionPageDateNifty">
                   
                    </select>
                    <!-- <label class="text-muted mr-md-2 mr-lg-2 mr-xl-2" for="sel1">From Strike:</label> -->
                    <select class="d-none custom-select my-select" id="FromStrikeNifty">
                   
                    </select>
                    <!-- <label class="text-muted mr-md-2 mr-lg-2 mr-xl-2" for="sel1">To strike:</label> -->
                    <select class="d-none custom-select my-select" id="ToStrikeNifty">
                   
                    </select>
                    <button type="submit" onclick="optionPageButtonInputDet()" class="btn btnRelative  ml-md-2 ml-lg-2 ml-xl-2 btninputfield ">Show Chart</button>
                  </form>
                </div>
                <div class="col-md-2 col-lg-2 col-xl-2 pl-md-0 pl-lg-0 pl-xl-0">
                  <div id="sample">
                  <h5 class="vertical">LAST UPDATED: <span id="dateDetailsOptions"></span></h5>
                  </div>
                </div>
              </div>
            </div>
      
           </div>
            <div class="card-body">
              <div class="table-responsive">
                <div id="ceoiChart"></div>
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</section>

<section class="mt-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-body">
              <div class="table-responsive">
                <div id='optionLtpTable'></div>
              
              </div>
  </div>
  </div>
</div>
</div>
  </div>
  </section>

<section class="mt-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadow cumulative_card">
          <div class="card-header">
            <h6 class="m-0 font-weight-bold text-md-left text-lg-left text-center">CUMULATIVE OPTION OI</h6>
          </div>
          <div class="card-body">
           <div id="historyChart"></div>
           </div>
          </div>
       </div>
      </div>
    </div>
</section>

</section>
<div class="card option_expired" id="option_session_expired">
    <h5 class="card-header headerSession">Session Expired</h5>
    <div class="card-body session-square">
      <div class="d-flex clockDash">
        <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
        <i class="fa fa-sign-in" aria-hidden="true"></i>
        <p id="optionSessionModel"></p>
     </div>
     <div class="text-center">
         <button type="submit" class="btn optionCancel mr-3">ok</button>
     </div>
    </div>
</div>
<div class="spinner"><!-- Place at bottom of page --></div>

<app-footer></app-footer>