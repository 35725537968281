import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { LoginModel } from './login.model';
declare var postApi :any;
@Component({
  selector: 'app-loginform',
  templateUrl: './loginform.component.html',
  styleUrls: ['./loginform.component.css']
})
export class LoginformComponent implements OnInit {
 login = new LoginModel();
  constructor(
    private serviceService: ServiceService
  ) { }

  ngOnInit(): void {
    postApi()
  }
  submit(){
    // console.log("asaosajs");
    this.serviceService.login(this.login).subscribe(res=> {
      console.log(res);
    })
  }
}
