<app-navbar></app-navbar>
<section class="relative_side_navbar" id="relativeSideNavbarDet">

<section class="firstChart mt-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
         <div class="card shadow cardAreaPieChart">
           <div class="card-header relative_card_header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 col-lg-2 col-xl-3">
                  <div id="sample">
                  <h5 class="vertical">Relative Strength</h5>
                  </div>
                </div>
                <div class="col-md-12 col-lg-10 col-xl-9 m-auto">
                  <form class="form-inline fii_form_responsive" action="/action_page.php">
                    <label class="mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Index:</label>
                    <select class="custom-select my-select" id="ralativeNifty">
                    </select> 
                     <label class="ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Stock:</label>
                        <select class="custom-select my-select" id="indexTwoNifty">
                        </select>
              
                        <button type="submit" onclick="buttonRelative()" class="btn btnRelative  ml-md-2 ml-lg-2 ml-xl-2">Show Chart</button>
                  </form>
                </div>
              </div>
            </div>
      
           </div>
           <div class="card-body">
            <div id="relativeData"></div>
           </div>
           </div>
         </div>
       </div>
      </div>
</section>
</section>



<div class="card relative_expired" id="relative_session_expired">
  <h5 class="card-header headerSession">Session Expired</h5>
  <div class="card-body session-square">
    <div class="d-flex clockDash">
      <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
      <i class="fa fa-sign-in" aria-hidden="true"></i>
      <p id="relativeSessionModel"></p>
   </div>
       <button type="submit" class="btn relativeCancel mr-3">ok</button>
  </div>
</div>

<div id="jsonDataDiv"></div>
<div class="spinner"><!-- Place at bottom of page --></div>
<app-footer></app-footer>