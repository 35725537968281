<app-navbar></app-navbar>
<section class="contactUsDet">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <div class="card contactForm m-auto shadow">
                <div class="card-body">
                    <form data-toggle="validator" role="form">
                        <ul id="form-error" class="text-center"></ul>
                        <div class="form-group">
                          <label for="name">Name</label>
                          <input type="name" class="form-control" id="name" aria-describedby="emailHelp" required />
          
                        </div>
                        <div class="form-group">
                          <label for="subject">Subject</label>
                          <input type="name" class="form-control" id="subject" required />
                        </div>
                        <div class="form-group">
                            <label for="comment">Issue Description:</label>
                            <textarea class="form-control" rows="5" id="comment" required ></textarea>
                          </div>
                          <div class="text-center">
                        <button type="submit" onclick="sendSupport();" id="contactForm" class="btn btn_contact_delta">Submit</button>
                        </div>
                      </form>
                </div>
            </div>
            
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>