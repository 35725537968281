import { Component, OnInit } from '@angular/core';
declare var sessionLoad,hideReload :any;
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // sendSupport();
    sessionLoad();
    hideReload();
  }

}
