import { Component, OnInit } from '@angular/core';
declare var relativeDataListApi,sessionLoad,hideReload;
declare var  start : any;
// declare var test;
@Component({
  selector: 'app-relative-strength',
  templateUrl: './relative-strength.component.html',
  styleUrls: ['./relative-strength.component.css']
})
export class RelativeStrengthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {  
    start();
    relativeDataListApi();
    sessionLoad(); 
    hideReload();
  } 

}
