<app-navbar></app-navbar>
<section class="stratgey_side_navbar" id="strategy-data-det">
<p class="d-none" id="addStrategyId"></p>
<input type="hidden" id="tbStrategyId" value="" />
<div class="modal" tabindex="-1" role="dialog" id="exampleModal123">
  <form method="post" action="#">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="squareoff_modal_title"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
              </div>
              <div class="modal-body">
                  <div class="form-group">
                      <label for="txtName">Name</label>
                      <input type="text" class="form-control" id="txtName" placeholder="Enter name" name="Name">
                  </div>
                  <div class="form-group">
                      <label for="txtName">Salary</label>
                      <input type="number" class="form-control" id="txtSalary" placeholder="Enter salary" name="Salary">
                  </div>
             </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" class="btn btn-primary">Update</button>
              </div>
          </div>
      </div>
  </form>
</div>
<section class="firstChart mt-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="previousStrategy1" id="strategy_chain">
       <div class="card shadow cardAreaPieChart">
        <div class="card-header relative_card_header" id="strategy_card_header">
          <div class="container-fluid">
            <div class="row ">
              <div class="col-md-6 strategy_first_col">
                <div id="strategy_header_det">
                  <h5 class="strategy_button_det">STRATEGY BUILDER</h5>
                  </div>
              </div>
              <div class="col-md-6 pl-2 strategy_second_col m-auto">
                <button type="button" class="btn addButtonStra float-right" onclick="openStrategy()">ADD<span>&nbsp;STRATEGY</span>
                </button>
              </div>
            </div>
          </div>
       </div>
     
          <div class="previousStrategy">
      </div>

      
       </div>
      </div>
     </div>
    </div>
    </div>
</section>
<section class="strategyDetBuild mt-4">
  <div class="container-fluid">
    <div class="row ">
      <div class="col-md-3">
    </div>
      <div class="col-md-9">
        <section class="mt-3" id="analyzeSet">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <h1>anil</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>
<section class="mt-3">
  <div class="container-fluid">
    <div class="row ">
      <div class="col-md-12">
        <div class="card cardTbaleDet" id="strategyTable">
          <div class="card-body addcardstrategy">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First</th>
                  <th scope="col">Last</th>
                  <th scope="col">Handle</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <th sbfcope="row">3</th>
                  <td>Larry</td>
                  <td>the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="spinner"><!-- Place at bottom of page --></div>




<p class="d-none" id="timeOptionChain"></p>

<!-- <div id="myModalSquare" class="modalSquare">
<div class="modal-square">
<span class="closeSquare" id="closeTag">&times;</span>
<p id="errorSquare"></p>
 </div>
 </div> -->


 <div id="myModalSquare" class="modalSquare">
  <div class="card positions_square_card">
    <div class="card-header positions_card_header_squareoff">
      <!-- <span class="closeSquare" id="closeTag">&times;</span> -->
      <h5 id="errorSquare"></h5>
    </div>
    <div class="card-body text-center">

    <button type="submit" class="btn closeSquare mr-3">ok</button>
    <!-- <button type="submit" class="btn cancelSquare">cancel</button> -->
    </div>
  </div>
  </div>

<div id="myModalSquare1" class="modalSquare">
  <div class="card positions_square_card">
    <div class="card-header positions_card_header_squareoff">
      <i class="fa fa-question-circle" aria-hidden="true"></i>
      <h5 id="errorSquare2"></h5>
    </div>
    <div class="card-body text-center">

    <button type="submit" class="btn okSquare mr-3">ok</button>
    <button type="submit" class="btn cancelSquare">cancel</button>
    </div>
  </div>
  </div>


<div id="myModalSquare2" class="modalSquare">
<div class="card positions_square_card">
  <div class="card-header positions_card_header_squareoff">
    <i class="fa fa-question-circle" aria-hidden="true"></i>
    <h5 id="errorSquare3"></h5>
  </div>
  <div class="card-body text-center">
    
  
  <button type="submit" class="btn okSquare1 mr-3">ok</button>
  <button type="submit" class="btn cancelSquare1">cancel</button>
  </div>
</div>
</div>

</section>
<div class="card strategy_expired" id="strategy_session_expired">
  <h5 class="card-header headerSession">Session Expired</h5>
  <div class="card-body session-square">
    <div class="d-flex clockDash">

      <i class="fa fa-sign-in" aria-hidden="true"></i>
      <p id="strategySessionModel"></p>
   </div>
   <div class="text-center">
       <button type="submit" class="btn strategyCancel mr-3">ok</button>
   </div>
  </div>
</div>

<app-footer></app-footer>