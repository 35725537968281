<!-- <section class="contactFooter mt-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 footer_delta">
        <p class="pl-4"><a target="_blank" href="#">Copyrights 2021.  DeltaCapitis.com. All Rights Reserved</a></p>
      </div>
      <div class="col-md-4 footer_delta">
        <p class="text-center pl-4"><a target="_blank" href="https://www.orangedigitalmedia.in/">Designed & Developed By Orange Digital Media</a></p>
      </div>
      <div class="col-md-4 footer_delta">
        <p class="pl-4"><a target="_blank" href="#" onclick="return false;">Privacy Policy</a><span><a target="_blank" href="#" onclick="return false;"> - Disclaimer</a></span></p>
      </div>
    </div>
  </div>
</section> -->



<section class="delta_Footer" id="capitisfooter">
<div class="container-fluid">
  <div class="row">
    <div class="col-md-4 pl-md-0 pl-lg-0">
      <div id="sample">
        <h5 class="vertical">Copyrights 2021.  DeltaCapitis.com. All Rights Reserved</h5>
      </div>
    </div>
    <div class="col-md-4">
      <div id="sample">
        <h5 class="vertical">Designed & Developed By : Orange Digital Media</h5>
      </div>
    </div>
    <div class="col-md-4">
      <div id="sample">
        <h5 class="vertical">Privacy Policy & Disclaimer</h5>
      </div>
    </div>
  </div>
</div>
</section>


