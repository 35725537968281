import { Component, OnInit } from '@angular/core';
declare var sidenavbar : any;
declare var bodyChangeColor,dashboardApiLoad,scalperTimer : any;
declare var toggleBg,sidenavbarTest,postApi : any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // toggleBg();
    // scalperTimer();
    dashboardApiLoad();
    // sidenavbar();
  }
  changeColor() {
    bodyChangeColor();
  
  }
  

}
