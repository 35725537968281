<app-navbar></app-navbar>
<section class="meter_side_navbar" id="meter-data-det">
  <section class="firstChart mt-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
         <div class="card shadow cardAreaPieChart">
          <div class="card-header relative_card_header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 col-lg-3 col-xl-3">
                  <div id="sample">
                  <h5 class="vertical">Index Strength Meter</h5>
                  </div>
                </div>
                <div class="col-md-12 col-lg-9 col-xl-9 m-auto">
                  <form class="form-inline indexmeter_form_responsive" action="/action_page.php">
                    <label class="mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select:</label>
                    <select class="custom-select my-select" id="selectSymbolIndexMeter">
                  
                    </select> 
                    <label class="ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Year:</label>
                    <select class="custom-select my-select" id="selectIndexyear">
                    </select>

                    <label class="ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">View Previous Year:</label>
                    <select class="custom-select my-select" id="selectIndexprevious">
               
                    </select> 
                    <button type="submit" onclick="indexMeterinputload()" class="btn btnRelative ml-md-2 ml-lg-2 ml-xl-2 btninputfield ">Show Chart</button>
                  </form>
                </div>
              </div>
            </div>
      
           </div>
         <div class="card-body">
            <div id="indexMeterNifty"></div>
         </div>
           </div>
         </div>
       </div>
      </div>
  </section>
  <section class="indexTable mt-3">
     <div class="container-fluid">
       <div class="row">
        <div class="col-md-12 mt-2">
          <div class="card shadow">
            <div class="card-header OverviewDet_card_header">
              <h6 class="m-0 font-weight-bold trend">Overview For <span id="TrendAnalyserDetails"></span></h6>
          </div>
          <div class="card-body"> 
            <div class="table-responsive">
              <div id='OverviewTable'></div>
            </div>
          </div>
           </div>
        </div>
         <div class="col-md-12 mt-3 mt-lg-3 mt-xl-3 mt-md-3">
           <div class="card shadow">
            <div class="card-header OverviewDet_card_header">
              <h6 class="m-0 font-weight-bold trend">Trend Analyser Data For <span id="OverviewDet"></span></h6>
          </div>
          <div class="card-body">
            <h6 class="m-0 mb-2 font-weight-bold lastUpdatedDet">Last Updated: <span id="lastTrendUpdate"></span></h6>
            <div class="table-responsive">
              <div id='TrendTableDet'></div>
            </div>
          </div>
           </div>
         </div>

       </div>
     </div>
  </section>
  </section>

     <div class="card meter_expired" id="meter_session_expired">
      <h5 class="card-header headerSession">Session Expired</h5>
      <div class="card-body session-square">
        <div class="d-flex clockDash">
          <i class="fa fa-clock-o" aria-hidden="true"></i>
          <p id="meterSessionModel"></p>
       </div>
       <div class="text-center">
           <button type="submit" class="btn meterCancel mr-3">ok</button>
           </div>
      </div>
    </div>
    <div class="spinner"><!-- Place at bottom of page --></div>
  <app-footer></app-footer>