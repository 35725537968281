<section class="new_Navbar_Det shadow">
  <nav class="navbar navbar-expand-lg navbar-light first_logo_navbar">
    <a class="navbar-brand logo_Details"><img class="delta_logo" src="assets/images/logo_new.png" alt="Delta Capitis Logo"></a>
    <button class="navbar-toggler first_navbar_toggler_icon" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav ml-auto mr-auto">
        <li ngbDropdown class="nav-item dropdown">
          <div class="d-flex">
          <i class="fa fa-user user_login" aria-hidden="true"></i>&nbsp;<a ngbDropdownToggle class="nav-link navUserDet dropdown-toggle" href="#" id="result-name" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
          <!-- <div class="header_img"> <img src="https://i.imgur.com/hczKIze.jpg" alt=""> </div> -->
        <!-- <a ngbDropdownToggle class="nav-link navUserDet dropdown-toggle" href="#"  id="result-name" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a> -->

          <div ngbDropdownMenu class="dropdown-menu logout_menu_details" aria-labelledby="navbarDropdown">
         
            <button class="dropdown-item" onclick="logout();" id="logout">Logout</button>
            
          </div>
        </div>
        </li>
      </ul>
    </div>
  </nav>
</section>
<div id="body-pd">
  <header class="header" id="header">
    <nav class="navbar navbar-expand-lg navbar-light delta_navbar">
      <!-- <a class="navbar-brand" routerLink="/dashboard" routerLinkActive="active"><img src="assets/images/logo.png" alt="Delta Capitis Logo"></a> -->

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto" id="nav_new_padding">
          <li class="nav-item active">
            <a class="nav-link navdetrtt" onclick="activeClass();" routerLink="/dashboard" routerLinkActive="active">Dashboard <span class="sr-only"></span></a>
          </li>
          <li ngbDropdown class="nav-item dropdown">
            <a ngbDropdownToggle class="nav-link navdetrtt dropdown-toggle" href="#" onclick="return false;" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Market Breadth
            </a>
            <div ngbDropdownMenu class="dropdown-menu customer_delta_drop shadow" aria-labelledby="navbarDropdown">
           
              <a class="dropdown-item delta_service_anchor"  routerLink="/relative_strength" routerLinkActive="active">Relative Strength</a>
              <a class="dropdown-item secondaryAnchor" routerLink="/sector_wise_performance" routerLinkActive="active">*Sectoral Index Data</a>
              <a class="dropdown-item secondaryAnchor" href="#" onclick="return false;">*Sector Performance</a>
              <a class="dropdown-item delta_service_anchor" routerLink="/index_fii_data" routerLinkActive="active">FII Data</a>
             
              <a class="dropdown-item delta_service_anchor" routerLink="/indexmeter" routerLinkActive="active">Index Meter</a>
              <a class="dropdown-item delta_service_anchor" routerLink="/Volume_speaks" routerLinkActive="active">Volume Speaks</a>
              
            </div>
          </li>
          <li ngbDropdown class="nav-item dropdown">
            <a ngbDropdownToggle class="nav-link navdetrtt dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Futures
            </a>
            <div ngbDropdownMenu class="dropdown-menu customer_delta_drop" aria-labelledby="navbarDropdown">
              <a class="dropdown-item delta_service_anchor" routerLink="/Future_Coi" routerLinkActive="active">Future COI</a>
              <a class="dropdown-item secondaryAnchor" href="#" onclick="return false;">*Sectoral OI Analysis</a>
              <a class="dropdown-item secondaryAnchor" href="#" onclick="return false;">*OI v/s Time</a>
              <a class="dropdown-item secondaryAnchor" href="#" onclick="return false;">*Intensity Maps</a>
              <a class="dropdown-item secondaryAnchor" href="#" onclick="return false;">*Stock Level Data</a>
              <a class="dropdown-item secondaryAnchor" href="#" onclick="return false;">*Futures Scanners</a>
            </div>
          </li>
          <li ngbDropdown class="nav-item dropdown">
            <a ngbDropdownToggle class="nav-link navdetrtt dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Options
            </a>
            <div ngbDropdownMenu class="dropdown-menu customer_delta_drop" aria-labelledby="navbarDropdown">
              <a class="dropdown-item delta_service_anchor" routerLink="/optionChain" routerLinkActive="active">Option Chain</a>
              <a class="dropdown-item delta_service_anchor" routerLink="/options" routerLinkActive="active">Options</a>
              <a class="dropdown-item secondaryAnchor" routerLink="/optionCOI" routerLinkActive="active">*Option COI</a>
              <a class="dropdown-item delta_service_anchor"[routerLink]="['/scalper']" routerLinkActive="active">Scalper</a>
              <a class="dropdown-item delta_service_anchor" routerLink="/PCR" routerLinkActive="active">PCR</a>
              <a class="dropdown-item delta_service_anchor" routerLink="/IV_Overview" routerLinkActive="active">IV</a>
              <a class="dropdown-item delta_service_anchor" routerLink="/Straddles/Strangle" routerLinkActive="active">Straddle/Strangle Charts</a>
             
              <a class="dropdown-item delta_service_anchor" routerLink="/strategy_builder" routerLinkActive="active">Option Strategy</a>

              <a class="dropdown-item delta_service_anchor" routerLink="/Option_OI_Analysis" routerLinkActive="active">Option OI Analysis</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link navdetrtt" routerLink="/Eod_Charts" routerLinkActive="active">Charts</a>
          </li>
          <li class="nav-item">
              <a class="nav-link navdetrtt" routerLink="/Contact_Us" routerLinkActive="active">Contact&nbsp;<span>Us</span></a>
          </li>
      


      </ul>


      <a class="btn btn_tutorial ml-md-4 ml-xl-4 ml-lg-4" id="tutorial_button" href="#" onclick="return false;">Tutorial</a>
      </div>

    
  </nav>
  <div id="reload_refresh_icon" class="hide_refresh_timer">
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center">
      <h6 class="mt-2 ml-2 mr-md-2 mr-lg-2 mr-xl-2" id="refresh_timer">Refresh In Sec:</h6>
      <div id="pie" class="ten">
        <div id="scalpercounter"></div>
      </div>
    </div>
  </div>
  </div>
  </header>
  </div>
  <div class="l-navbar" id="nav-bar">
      <nav class="nav">
         <div> 
          <div class="nav_list">
          <div class="nav_link"><i class="fa fa-bars bx bx-menu" onclick="openNavbar()" id="header-toggle" aria-hidden="true"></i></div>
          <a class="nav_link" routerLink="/dashboard" routerLinkActive="active"><img class="market_breadth" src="assets/images/dashboard1.png" onclick="openNavbar()" id="header-toggle"   alt="Delta Capitis Logo" align="left"><span class="nav_name">Dashboard</span> </a>  
          <div class="dropdown">
          <a href="#" class="nav_link" id="dropdownMenuButton_market" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img class="market_breadth" src="assets/images/market_breadth.png"  onclick="openNavbar()" id="header-toggle"  alt="Delta Capitis Logo" align="left"><span class="nav_name">Market Breadth&nbsp;<i class="fa fa-caret-down future_caret" aria-hidden="true"></i></span> </a> 
          <div class="dropdown-menu dropdown_new_css" aria-labelledby="dropdownMenuButton_market">
              <a class="dropdown-item side_dropdown_det"  routerLink="/relative_strength" routerLinkActive="active">Relative Strength</a>
              <a class="dropdown-item side_Anchor" routerLink="/sector_wise_performance" routerLinkActive="active">*Sectoral Index Data</a>
              <a class="dropdown-item side_Anchor" href="#" onclick="return false;">*Sector Performance</a>
              <a class="dropdown-item side_dropdown_det" routerLink="/index_fii_data" routerLinkActive="active">FII Data</a>
              <a class="dropdown-item side_dropdown_det" routerLink="/indexmeter" routerLinkActive="active">Index Meter</a>
              <a class="dropdown-item side_dropdown_det" routerLink="/Volume_speaks" routerLinkActive="active">Volume Speaks</a>
            </div>
          </div>
          <div class="dropdown">
            <a href="#" class="nav_link" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img class="market_breadth" src="assets/images/futures.png" onclick="openNavbar()" id="header-toggle" alt="Delta Capitis Logo" align="left"><span class="nav_name">Futures&nbsp;<i class="fa fa-caret-down future_caret" aria-hidden="true"></i></span></a> 

            <!-- <a href="#" class="nav_link" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-paper-plane" aria-hidden="true"></i><span class="nav_name">Futures&nbsp;<i class="fa fa-caret-down future_caret" aria-hidden="true"></i></span> </a>  -->
            <div class="dropdown-menu dropdown_new_css" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item side_dropdown_det" routerLink="/Future_Coi" routerLinkActive="active">Future COI</a>
              <a class="dropdown-item secondaryAnchor" href="#" onclick="return false;">*Sectoral OI Analysis</a>
              <a class="dropdown-item  secondaryAnchor" href="#" onclick="return false;">*OI v/s Time</a>
              <a class="dropdown-item  secondaryAnchor" href="#" onclick="return false;">*Intensity Maps</a>
              <a class="dropdown-item  secondaryAnchor" href="#" onclick="return false;">*Stock Level Data</a>
              <a class="dropdown-item  secondaryAnchor" href="#" onclick="return false;">*Futures Scanners</a>
            </div>
          </div>
          <div class="dropdown">
            <a href="#" class="nav_link" id="dropdownMenuButton_option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img class="market_breadth" src="assets/images/options.png"  onclick="openNavbar()" id="header-toggle"  alt="Delta Capitis Logo" align="left"><span class="nav_name">Options&nbsp;<i class="fa fa-caret-down future_caret" aria-hidden="true"></i></span> </a> 

            <!-- <a href="#" class="nav_link" id="dropdownMenuButton_option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-paper-plane" aria-hidden="true"></i><span class="nav_name">Options&nbsp;<i class="fa fa-caret-down future_caret" aria-hidden="true"></i></span> </a>  -->
            <div class="dropdown-menu dropdown_new_css" aria-labelledby="dropdownMenuButton_option">
              <a class="dropdown-item side_dropdown_det" routerLink="/optionChain" routerLinkActive="active">Option Chain</a>
              <a class="dropdown-item side_dropdown_det" routerLink="/options" routerLinkActive="active">Options</a>
              <a class="dropdown-item secondaryAnchor" routerLink="/optionCOI" routerLinkActive="active">*Option COI</a>
              <a class="dropdown-item side_dropdown_det"[routerLink]="['/scalper']" routerLinkActive="active">Scalper</a>
              <a class="dropdown-item side_dropdown_det" routerLink="/PCR" routerLinkActive="active">PCR</a>
              <a class="dropdown-item side_dropdown_det" routerLink="/IV_Overview" routerLinkActive="active">IV</a>
              <a class="dropdown-item side_dropdown_det" routerLink="/Straddles/Strangle" routerLinkActive="active">Straddle/Strangle Charts</a>
             
              <a class="dropdown-item side_dropdown_det" routerLink="/strategy_builder" routerLinkActive="active">Option Strategy</a>

              <a class="dropdown-item side_dropdown_det" routerLink="/Option_OI_Analysis" routerLinkActive="active">Option OI Analysis</a>
            </div>
          </div>
          <a class="nav_link" onclick="logout();" id="logout"><img class="market_breadth" src="assets/images/signout.png"  alt="Delta Capitis Logo" align="left"><span class="nav_name">SignOut</span> </a> 
           </div>
          </div> 
      </nav>
  </div>
