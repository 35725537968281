<app-navbar></app-navbar>
<section class="cardSeletOption mt-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card shadow">
                    <div class="card-body align-items-center d-flex justify-content-center">
                    <!-- <form action="#" class="form-inline">
                        <select class="form-control" id="sel1">
                          <option>NIFTY</option>
                          <option>BANKNIFTY</option>
                          <option>FINNIFTY</option>
                          <option>ACC</option>
                        </select>
                        <label for="">Expiry Date:</label>
                        <select class="form-control" id="sel2">
                          <option>10MAR2021</option>
                          <option>18MAR2021</option>
                          <option>25MAR2021</option>
                          <option>01APR2021</option>
                        </select>
                        <button class="btn btnSelectOption">SUBMIT</button>
                    </form> -->
                    <!-- <form action="#" method="POST" class="form-inline">
                      <div class="row d-flex justify-content-center text-center">
                        <div class="colform col-12 col-md-4 col-lg-4 col-xl-4 pr-2">
                          <label class="text-muted" for="sel1">Select 1st Index:</label>
                          <select class="custom-select my-select" id="optionChainNifty">
      
                          </select> 
                        </div>
                        <div class="colform col-12 col-md-4 col-lg-4 col-xl-4 pr-2">
                          <label class="text-muted" for="sel1">Select 2nd Index/Stock</label>
                          <select class="custom-select my-select" id="expiryChainNifty">
                          
                          </select>
                        </div>
                        <div class="colform col-12 col-md-12 col-lg-3 col-xl-3 mt-3">
                          
                          <button type="submit" onclick="optionDatatable()" class="btn font-weight-bold mt-3 btninputfield">Show Chart</button>
                        </div>
                      </div>
                    </form> -->
                    <form class="form-inline" action="/action_page.php">
                      <label class="text-muted mr-md-2 mr-lg-2 mr-xl-2" for="sel1">Select Symbol:</label>
                      <select class="custom-select my-select" id="eodchartsSelect">
                        <!-- <option>NIFTY</option>
                        <option>BANKNIFTY</option>
                        <option>FINNIFTY</option>
                        <option>ACC</option> -->
                      </select> 
                      <label class="text-muted mr-md-2 mr-lg-2 mr-xl-2 ml-lg-2 ml-xl-2" for="sel1">Select Expiry:</label>
                      <select class="custom-select my-select" id="YearEodNifty">
                        <!-- <option>10MAR2021</option>
                        <option>18MAR2021</option>
                        <option>25MAR2021</option>
                        <option>01APR2021</option> -->
                      </select>
                
                      <button type="submit" onclick="futureeodButtonLoad()" class="btn btn-primary font-weight-bold btninputfield ml-2">Show Chart</button>
                    </form>
                    <!-- <div class="textOptionDet d-flex mt-3">
                        <p class="spotDet">spot price:12345</p>
                        <p class="futureDet">Futures Price: 14977.45</p>
                    </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="firstChart mt-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-lg-12">
       <div class="card shadow cardAreaPieChart">
      <!-- Card Header - Dropdown -->
         <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">
            FUTURES CUMULATIVE OI</h6>
         </div>
         <div class="card-body">
          <div id="futureeodData"></div>
         </div>
         </div>
       </div>
     </div>
    </div>
</section>

<div class="card dasbaord_expired" id="dashboard_session_expired">
  <h5 class="card-header headerSession">Session Expired</h5>
  <div class="card-body session-square">
    <div class="d-flex clockDash">
      <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
      <i class="fa fa-sign-in" aria-hidden="true"></i>
      <p id="dasboardSessionModel"></p>
   </div>
   <div class="text-center">
       <button type="submit" class="btn dashboardCancel mr-3">ok</button>
       </div>
  </div>
</div>


