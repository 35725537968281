import { Component, OnInit } from '@angular/core';
declare var setFutureRadio,futureCoiDataListApi,sessionLoad,hideReload;
declare var  start : any;
@Component({
  selector: 'app-future-coi',
  templateUrl: './future-coi.component.html',
  styleUrls: ['./future-coi.component.css']
})
export class FutureCOIComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    start();
    setFutureRadio();
    futureCoiDataListApi();
    sessionLoad();
    hideReload();
  }

}
