<app-navbar></app-navbar>
<section class="pcr_side_navbar" id="pcr-data-det">
<!-- <section class="mt-2">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card shadow">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row align-items-center d-flex justify-content-center">
                <div class="col-md-3 align-items-center d-flex justify-content-center">
                </div>
                <div class="col-md-3 align-items-center d-flex justify-content-center">
              <div class="d-flex align-items-center">
                <h6 class="mt-2 ml-2 mr-2">Refresh In Sec:</h6>
                <div id="countdown">
                  <div id="countdownpcr"></div>
                  <svg>
                    <circle r="18" cx="20" cy="20"></circle>
                  </svg>
                </div>
              </div>
             </div>


             </div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<section class="firstChart mt-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
         <div class="card shadow cardAreaPieChart">

                    <!-- Card Header - Dropdown -->
          <div class="card-header relative_card_header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 col-lg-2 col-xl-2">
                  <div id="sample">
                  <h5 class="vertical">PCR DATA</h5>
                  </div>
                </div>
                <div class="col-md-12 col-lg-7 col-xl-7 m-auto">
                  <form class="form-inline pcr_form" action="/action_page.php">
                    <label class="mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Symbol:</label>
                    <select class="custom-select my-select" id="pcrSymbol">
            
                    </select>
                    <label class="ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Date:</label>
                    
                   
                    <select class="custom-select my-select" id="selectDate">
               
                    </select>
                    <button type="submit" onclick="pcrButtonLoad()" class="btn btnRelative font-weight-bold ml-md-2 ml-lg-2 ml-xl-2 btninputfield ">Show Chart</button>
                  </form>
                </div>
                <div class="col-md-12 col-lg-3 col-xl-3">
                  <div id="sample">
                  <h5 class="verticalpcr">LAST UPDATED: <span id="pcrdateDetails"></span></h5>
                  </div>
                </div>
              </div>
            </div>
      
           </div>
         <div class="card-body">
            <div id="pcrData"></div>
         </div>
           </div>
         </div>
       </div>
      </div>
</section>
</section>

   
   <div class="card pcr_expired" id="pcr_session_expired">
    <h5 class="card-header headerSession">Session Expired</h5>
    <div class="card-body session-square">
      <div class="d-flex clockDash">
        <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
        <i class="fa fa-sign-in" aria-hidden="true"></i>
        <p id="pcrSessionModel"></p>
     </div>
     <div class="text-center">
         <button type="submit" class="btn pcrCancel mr-3">ok</button>
         </div>
    </div>
  </div>
  <div class="spinner"><!-- Place at bottom of page --></div>
<app-footer></app-footer>