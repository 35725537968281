<app-navbar></app-navbar>
<section class="fii_side_navbar" id="fii-data-det">
<section class="firstChart mt-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
         <div class="card shadow cardAreaPieChart">
          <div class="card-header relative_card_header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 col-lg-2 col-xl-3">
                  <div id="sample">
                  <h5 class="vertical">Index Fii Data</h5>
                  </div>
                </div>
                <div class="col-md-12 col-lg-10 col-xl-9 m-auto">
                  <form class="form-inline fii_form_responsive" action="/action_page.php">
                    <label class="mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select:</label>
                    <select class="custom-select my-select" id="selectSymbol_fii_data">
                    </select>
                    <label class="ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Year:</label>
                    <select class="custom-select my-select" id="selectyear">
                    </select>
                    <label class="ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">View Previous Year:</label>
                    <select class="custom-select my-select" id="selectprevious">
                    </select> 
                    <button type="submit" onclick="fiiinputload()" class="btn btnRelative ml-md-2 ml-lg-2 ml-xl-2 btninputfield ">Show Chart</button>
                  </form>
                </div>
              </div>
            </div>
      
           </div>
         <div class="card-body">
            <div id="indexChart"></div>
         </div>
           </div>
         </div>
       </div>
      </div>
</section>
</section>
   <div class="card index_expired" id="index_session_expired">
      <h5 class="card-header headerSession">Session Expired</h5>
      <div class="card-body session-square">
         <div class="d-flex clockDash">
          <i class="fa fa-sign-in" aria-hidden="true"></i>
          <p id="indexSessionModel"></p>
        </div>
         <div class="text-center">
            <button type="submit" class="btn indexCancel mr-3">ok</button>
        </div>
      </div>
    </div>
    <div class="spinner"><!-- Place at bottom of page --></div>
  <app-footer></app-footer>