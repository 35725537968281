<app-navbar></app-navbar>
<section class="straddle_side_navbar" id="straddle-data-det">
  <section class="firstChart mt-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="card shadow cardAreaPieChart">
            <div class="card-header relative_card_header">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12 col-lg-1 col-xl-1">
                    <div id="sample">
                      <h5 class="vertical">STRADDLE<span>&nbsp;DATA</span></h5>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-9 col-xl-9 m-auto">
                    <form class="form-inline straddle_form" action="/action_page.php">
                      <label class="mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select Symbol:</label>

                      <select class="custom-select my-select" id="selectSymbol">
                      </select>
                      <label class="ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Select
                        Date:</label>


                      <select class="custom-select my-select" id="selectDate">

                      </select>
                      <label class="ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2 fii_label" for="sel1">Strike
                        Call:</label>

                      <!-- nan_code start -->
                      <div class="nan_code d-flex">
                        <div class="multi-select"></div>
                        <label class="ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2 ml-2 fii_label" for="sel1">Strike
                          Put:</label>
                        <div class="multi-select-2"></div>
                      </div>
                      <!-- nan_code end -->


                      <!-- <div class="multi-select"></div> -->
                      <div id="demo"></div>
                      <!-- <div class="multi-select3"></div> -->
                      <div id="demo"></div>
                      <!-- <label class="text-muted ml-md-2 ml-lg-2 ml-xl-2 mr-md-2 mr-lg-2 mr-xl-2" for="sel1">Strike Put:</label> -->

                      <!-- <div class="multi-select-2"></div> -->
                      <div id="strikeemc"></div>
                      <!-- <div class="multi-select-4"></div> -->
                      <div id="strikeemc"></div>
                      <button type="submit" onclick="straddleButtonLoad()"
                        class="btn btnRelative ml-md-2 ml-lg-2 ml-xl-2 btninputfield ">Show Chart</button>
                    </form>
                  </div>
                  <div class="col-md-12 col-lg-2 col-xl-2 pl-lg-0 pl-xl-0">
                    <div id="sample">
                      <h5 class="verticalpcr">LAST UPDATED: <span id="straddledateDetails"></span></h5>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="card-body">
              <div id="straddledata"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</section>
<div class="card stra_expired" id="stra_session_expired">
  <h5 class="card-header headerSession">Session Expired</h5>
  <div class="card-body session-square">
    <div class="d-flex clockDash">
      <!-- <i class="fa fa-clock-o" aria-hidden="true"></i> -->
      <i class="fa fa-sign-in" aria-hidden="true"></i>
      <p id="straSessionModel"></p>
    </div>
    <div class="text-center">
      <button type="submit" class="btn straCancel mr-3">ok</button>
    </div>
  </div>
</div>

<div class="spinner">
  <!-- Place at bottom of page -->
</div>


<app-footer></app-footer>