import { Component, OnInit } from '@angular/core';
declare var fiiDataListApi,sessionLoad,hideReload : any;
declare var  start : any;  
@Component({
  selector: 'app-index-fii-data',
  templateUrl: './index-fii-data.component.html',
  styleUrls: ['./index-fii-data.component.css']
})
export class IndexFiiDataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    start();
    fiiDataListApi();
    sessionLoad();
    hideReload();
  }

}
