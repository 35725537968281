import { Component, OnInit } from '@angular/core';
declare var FuturesEodChartsOnload,eodChartOnLoading,sessionLoad,getActualYear:any;
@Component({
  selector: 'app-volumespeakseod',
  templateUrl: './volumespeakseod.component.html',
  styleUrls: ['./volumespeakseod.component.css']
})
export class VolumespeakseodComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    FuturesEodChartsOnload();
    // getActualYear();
    // eodChartOnLoading()
    sessionLoad();
  }

}
